interface FooterType {
  version: string
  build: string
}

export function Footer({version, build}: FooterType) {

  return (
      <div
          className="position-absolute bottom-0 text-center w-100 text-dark small">version {version} (build {build})</div>
  )

}


import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { AUDIENCE, AUTH_DOMAIN, CLIENT_ID, REDIRECT_URL } from "./constants/AuthConstants";
import App from './App';
import './App.css';
import { isIOS } from "./utils/Mobile";
import './i18n/init';

const Root = () => {
  if (isIOS()) return <App/>;
  return (
      <Auth0Provider domain={AUTH_DOMAIN} clientId={CLIENT_ID} redirectUri={REDIRECT_URL} audience={AUDIENCE}>
        <App/>
      </Auth0Provider>);

};

ReactDOM.render(<Root/>, document.body.appendChild(document.createElement('div')));
import i18n from 'i18next';
import k from "./../../i18n/keys";
import { useContext, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';

import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { isOrgAdmin } from "../../utils/Access";
import { DataContext } from "../../contexts/DataContext";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Device } from "../../types";
import { DeviceContext } from "../../contexts/DeviceContext";

// @ts-ignore
import { ReactComponent as LogoIcon } from '../../static/logo.svg';

const AvatarImage = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;
const HeaderIcon = styled(FontAwesomeIcon)`
  margin-top: 8px;
`;
const HeaderTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HeaderTitleWrapper = styled(NavbarBrand)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CountBadge = styled(Badge)`
  top: 10px;
  left: 25px;
  padding: 1px 2px;
  min-width: 18px;
  border-radius: 0.5rem;
`;

interface HeaderType {
  top: number
}


export function Header({top}: HeaderType) {

  const location = useLocation();
  const {auth, logoutUser} = useContext(AuthContext);
  const {config, devices} = useContext(DataContext);
  const {setDevice} = useContext(DeviceContext);
  const {notifications, showNotifications, setShowNotifications} = useContext(NotificationContext);
  const [profileOpen, setProfileOpen] = useState(false);

  const hasTitle = config?.data?.header?.title && config?.data?.header?.title.length > 0;
  const hasCustomLogo = config?.data?.header?.logo;
  const hasMoreDevices: boolean = auth?.roles.length === 0 && devices?.data !== undefined && devices?.data?.length > 0;

  const switchResident = (device: Device) => {
    console.log('Device', device.id, 'selected');
    setDevice(device);
  };

  const OrgNavs = () =>
      <>
        <NavItem className="d-none d-sm-block">
          <NavLink tag={Link} to="/" active={location?.pathname === "/"}
                   className="text-white">{i18n.t(k.DASHBOARD)}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/residents" active={location?.pathname.startsWith("/residents")}
                   className="text-white">{i18n.t(k.RESIDENTS)}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/alerts" active={location?.pathname.startsWith("/alerts")}
                   className="text-white">{i18n.t(k.ALERTS)}</NavLink>
        </NavItem>
      </>;


  const DeviceSwitch = () =>
      <>
        <DropdownItem divider/>
        {devices?.data?.map((d) => <DeviceItem key={d.id} device={d}/>)}
      </>;


  const DeviceItem = ({device}: any) =>
      <DropdownItem key={device.id}
                    id={`${i18n.t(k.SWITCH_PROFILE)}${device.resident?.name?.replaceAll(" ", "").toLowerCase()}`}
                    onClick={() => switchResident(device)}>
        <FontAwesomeIcon icon={faUserCircle} className="me-3"/>
        {device.resident.name}
      </DropdownItem>;


  const DefaultLogo = () => <LogoIcon className="text-white" style={{width: 30, height: 30}}/>;
  const CustomLogo = () => <img src={config?.data?.header?.logo} height="32" alt="company logo"
                                className="text-white"/>;

  return (
      <Navbar dark fixed="top" expand="xs" className="navbar-dark bg-primary" style={{top: top, height: 50}}>
        <HeaderTitleWrapper tag={Link} to="/" className="ps-2 d-flex">
          {hasCustomLogo ? <CustomLogo/> : <DefaultLogo/>}
          {hasTitle && <HeaderTitle className="ps-3 pt-1 fw-light small">{config?.data?.header.title}</HeaderTitle>}
        </HeaderTitleWrapper>
        <NavbarToggler onClick={function noRefCheck() {
        }}/>
        <Nav navbar className="justify-content-center">
          {isOrgAdmin(auth) && <OrgNavs/>}
        </Nav>
        <Nav navbar>
          <NavItem>
            <NavLink id="notifications-action" className="position-relative pe-3 cursor-pointer"
                     onClick={() => setShowNotifications(!showNotifications)}>
              <HeaderIcon icon={faBell}/>
              {notifications.data && notifications.data?.length > 0 &&
                <CountBadge pill className="bg-danger position-absolute">{notifications.data?.length}</CountBadge>}
            </NavLink>
          </NavItem>
          <Dropdown nav id="profile-dropdown" isOpen={profileOpen} toggle={() => setProfileOpen(!profileOpen)}>
            <DropdownToggle nav>
              <AvatarImage src={auth?.avatar} className="ms-2"/>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem id="profile-action" tag={Link} to="/profile">{i18n.t(k.MY_PROFILE)}</DropdownItem>
              <DropdownItem id="settings-action" tag={Link} to="/settings">{i18n.t(k.SETTINGS)}</DropdownItem>
              {hasMoreDevices && <DeviceSwitch/>}
              <DropdownItem divider/>
              <DropdownItem id="logout-action" onClick={logoutUser}>{i18n.t(k.LOGOUT)} {auth?.name}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>);


}
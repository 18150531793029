export enum Feature {
  CALL,
  POSTS,
  ANOMALIES,
  DOCUMENTS,
  METRICS,
  CONTACTS,
  CALENDAR,
  ACTIVITY,
  VITALS,
  MOOD,
  PATHWAYS,
  PILLS,
  SETTINGS
}



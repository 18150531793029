import { useContext } from "react";
import { Button, Input } from "reactstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFaceFlushed, faFaceFrown, faFaceLaugh, faFaceMeh, faFaceSmile } from "@fortawesome/free-regular-svg-icons";

import { NotificationContext } from "../../contexts/NotificationContext";
import { Notification } from '../../types'
import { isMobile } from "../../utils/Mobile";

interface EditorType {
  notification: Notification
  info: Record<string, any>
  data: Record<string, Record<string, any>>
  setData: (data: Record<string, Record<string, any>>) => void
}

function Buttons({data, notification, defaultValue}: any) {

  const {resolveNotification} = useContext(NotificationContext)
  const submit = () => resolveNotification(notification.id, 'submit', data[notification.id] || {value: defaultValue})
  const showless = () => resolveNotification(notification.id, 'showless')

  return (
      <div className="mt-2">
        <Button size="sm" color="primary" className="mt-3 ms-3 float-end" onClick={submit}>Confirm the reading</Button>
        <Button size="sm" outline color="warning" className="mt-3 float-end" onClick={showless}>Show less of
          these</Button>
      </div>
  )
}

function DismissConfirmButtons({data, notification, defaultValue}: any) {

  const {resolveNotification} = useContext(NotificationContext)
  const submit = () => resolveNotification(notification.id, 'submit', data[notification.id] || {value: defaultValue})
  const refuse = () => resolveNotification(notification.id, 'refuse')

  return (
      <div className="mt-2">
        <Button size="sm" color="primary" className="mt-3 ms-3 float-end" onClick={submit}>Confirm the answer</Button>
        <Button size="sm" outline color="warning" className="mt-3 float-end" onClick={refuse}>I don't want to
          answer</Button>
      </div>
  )
}

export function SingleLinearScale({notification, info, data, setData}: EditorType) {
  return (
      <>
        <RangeSlider value={data[notification.id]?.value || info.value} variant="warning" min={info.min} max={info.max}
                     size="lg" tooltip="off" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {value: parseInt(event.target.value)}})}/>
        <div
            className="text-dark text-center">{`${info.label}: ${data[notification.id]?.value || info.value} ${info.unit}`}</div>
        <Buttons data={data} notification={notification} defaultValue={info.value}/>
      </>
  )
}

export function SingleEnumScale({notification, info, data, setData}: EditorType) {
  return (
      <>
        <RangeSlider value={data[notification.id]?.value || info.value} min={info.min} max={info.max} size="lg"
                     tooltip="off" variant="warning" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {value: event.target.value}})}/>
        <div className="text-dark text-center">{info.steps[data[notification.id]?.value || info.value]}</div>
        <Buttons data={data} notification={notification} defaultValue={info.value}/>
      </>
  )
}

export function DoubleLinearScale({notification, info, data, setData}: EditorType) {
  return (
      <>
        <RangeSlider value={data[notification.id]?.value1 || info[0].value} min={info[0].min} max={info[0].max}
                     size="lg" tooltip="off" variant="warning" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {...data[notification.id], value1: event.target.value}})}/>
        <div
            className="text-dark text-center">{`${info[0].label}: ${data[notification.id]?.value1 || info[0].value} ${info[0].unit}`}</div>
        <RangeSlider value={data[notification.id]?.value2 || info[1].value} min={info[1].min} max={info[1].max}
                     size="lg" tooltip="off" variant="danger" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {...data[notification.id], value2: event.target.value}})}/>
        <div
            className="text-dark text-center">{`${info[1].label}: ${data[notification.id]?.value2 || info[1].value} ${info[1].unit}`}</div>
        <Buttons data={data} notification={notification} defaultValue={{value1: info[0].value, value2: info[1].value}}/>
      </>
  )
}

export function BMIScale({notification, info, data, setData}: EditorType) {
  return (
      <>
        <RangeSlider value={data[notification.id]?.value1 || info[0].value} min={info[0].min} max={info[0].max}
                     size="lg" tooltip="off" variant="warning" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {...data[notification.id], value1: event.target.value}})}/>
        <div
            className="text-dark text-center">{`${info[0].label}: ${data[notification.id]?.value1 || info[0].value} ${info[0].unit}`}</div>
        <RangeSlider value={data[notification.id]?.value2 || info[1].value} min={info[1].min} max={info[1].max}
                     size="lg" tooltip="off" variant="danger" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {...data[notification.id], value2: event.target.value}})}/>
        <div
            className="text-dark text-center">{`${info[1].label}: ${data[notification.id]?.value2 || info[1].value} ${info[1].unit}`}</div>
        <Buttons data={data} notification={notification} defaultValue={{value1: info[0].value, value2: info[1].value}}/>
      </>
  )
}

export function SmileysScale({notification, info, data, setData}: EditorType) {
  return (
      <>
        <div className="d-flex flex-row justify-content-between cursor-pointer mx-4">
          <FontAwesomeIcon icon={faFaceFrown} size={isMobile() ? "lg" : "2x"}
                           className={`p-2 text-dark border border-2 border-${data[notification.id]?.value === 0 ? 'dark' : 'white'}`}
                           onClick={() => setData({...data, [notification.id]: {value: info.min}})}/>
          <FontAwesomeIcon icon={faFaceFlushed} size={isMobile() ? "lg" : "2x"}
                           className={`p-2 text-info border border-2 border-${data[notification.id]?.value === 1 ? 'dark' : 'white'}`}
                           onClick={() => setData({...data, [notification.id]: {value: info.min + 1}})}/>
          <FontAwesomeIcon icon={faFaceMeh} size={isMobile() ? "lg" : "2x"}
                           className={`p-2 text-info border border-2 border-${data[notification.id]?.value === 2 ? 'dark' : 'white'}`}
                           onClick={() => setData({...data, [notification.id]: {value: info.min + 2}})}/>
          <FontAwesomeIcon icon={faFaceSmile} size={isMobile() ? "lg" : "2x"}
                           className={`p-2 text-info border border-2 border-${data[notification.id]?.value === 3 ? 'dark' : 'white'}`}
                           onClick={() => setData({...data, [notification.id]: {value: info.min + 3}})}/>
          <FontAwesomeIcon icon={faFaceLaugh} size={isMobile() ? "lg" : "2x"}
                           className={`p-2 text-success border border-2 border-${data[notification.id]?.value === 4 ? 'dark' : 'white'}`}
                           onClick={() => setData({...data, [notification.id]: {value: info.min + 4}})}/>
        </div>
        <div className="text-dark text-center mt-2">{info.steps[data[notification.id]?.value]}</div>
        <Buttons data={data} notification={notification} defaultValue={info.value}/>
      </>
  )
}

export function Checkers({notification, info, data, setData}: EditorType) {

  const allfalse = info.reduce((acc: Record<string, any>, v: Record<string, any>) => {
    acc[v.value] = false;
    return acc
  }, {})

  const Check = ({info}: any) => (
      <div className="ms-4 ps-2">
        <Input type="checkbox" name={info.value}
               checked={data[notification.id] ? data[notification.id][info.value] : false}
               onChange={event => setData({...data, [notification.id]: {...data[notification.id], [event.target.name]: event.target.checked}})}/>
        <span className="ps-2">{info.label}</span>
      </div>
  )

  return (
      <>
        {info.map((i: any) => <Check key={i.label} info={i}/>)}
        <Buttons data={data} notification={notification} defaultValue={allfalse}/>
      </>
  )
}

export function OptionsScale({notification, info, data, setData}: EditorType) {
  const values = Object.keys(notification.options).map(v => parseInt(v))
  return (
      <>
        <RangeSlider value={data[notification.id]?.value || info.value} min={Math.min(...values)}
                     max={Math.max(...values)} size="lg" tooltip="off" variant="warning" className="px-5"
                     onChange={event => setData({...data, [notification.id]: {value: parseInt(event.target.value) || event.target.value}})}/>
        <div className="text-dark text-center">{notification.options[data[notification.id]?.value || info.value]}</div>
        <DismissConfirmButtons data={data} notification={notification} defaultValue={info.value}/>
      </>
  )
}

const keys = {
  "_": "_",
  "THE_HUB_IS_NOW_CONNECTED_TO_TH": "THE_HUB_IS_NOW_CONNECTED_TO_TH",
  "YOU_MAY_PROCEED_WITH_ADDING_SO": "YOU_MAY_PROCEED_WITH_ADDING_SO",
  "CONTINUE": "CONTINUE",
  "THERE_WERE_AN_ERROR_WHILE_CONN": "THERE_WERE_AN_ERROR_WHILE_CONN",
  "PLEASE_MAKE_SURE_THE_WIFI_IS_A": "PLEASE_MAKE_SURE_THE_WIFI_IS_A",
  "RETRY": "RETRY",
  "THERE_WERE_ANOMALIES": "THERE_WERE_ANOMALIES",
  "NORMAL": "NORMAL",
  "ACTIVITY": "ACTIVITY",
  "CARE_RECIPIENT_S_ACTIVITY": "CARE_RECIPIENT_S_ACTIVITY",
  "_1": "_1",
  "EVENTS": "EVENTS",
  "SMALL": "SMALL",
  "PX": "PX",
  "INSIGHTS": "INSIGHTS",
  "IMPORTANT_FINDINGS_RELATED_TO": "IMPORTANT_FINDINGS_RELATED_TO",
  "THIS_FEATURE_IS_UNAVAILABLE": "THIS_FEATURE_IS_UNAVAILABLE",
  "CONTACT_THE": "CONTACT_THE",
  "LA_CASA_SALES": "LA_CASA_SALES",
  "FOR_FURTHER_ASSISTANCE": "FOR_FURTHER_ASSISTANCE",
  "LONG_TIME_AGO": "LONG_TIME_AGO",
  "ENERGY_CLAMP": "ENERGY_CLAMP",
  "METRICS": "METRICS",
  "VALUES_SHOW_AVERAGE_VALUES_FOR": "VALUES_SHOW_AVERAGE_VALUES_FOR",
  "MOOD": "MOOD",
  "KEEPS_TRACK_OF_MOOD_CHANGES": "KEEPS_TRACK_OF_MOOD_CHANGES",
  "THIS_FEATURE_REQUIRES_LA_CASA": "THIS_FEATURE_REQUIRES_LA_CASA",
  "PLEASE_CONTACT_THE": "PLEASE_CONTACT_THE",
  "FOR_FURTHER_ASSISTANCE_OR_NAVI": "FOR_FURTHER_ASSISTANCE_OR_NAVI",
  "SETTINGS": "SETTINGS",
  "SECTION_IF_YOU_ARE_EQUIP": "SECTION_IF_YOU_ARE_EQUIP",
  "ACTIVE": "ACTIVE",
  "SINCE_AUG": "SINCE_AUG",
  "INFORMAL_CAREGIVERS_ARE_REMIND": "INFORMAL_CAREGIVERS_ARE_REMIND",
  "FORMAL_CAREGIVERS_ARE_INFORMED": "FORMAL_CAREGIVERS_ARE_INFORMED",
  "THERE_ARE_PHYSICAL_ACTIVITIES": "THERE_ARE_PHYSICAL_ACTIVITIES",
  "NUTRITION_SPECIALIST_IS_CONTAC": "NUTRITION_SPECIALIST_IS_CONTAC",
  "THE_CARE_RECIPIENT_IS_WARNED_A": "THE_CARE_RECIPIENT_IS_WARNED_A",
  "INTERVENTION_PATHWAYS": "INTERVENTION_PATHWAYS",
  "KEEPS_TRACK_OF_THE_HEALING": "KEEPS_TRACK_OF_THE_HEALING",
  "MMHG": "MMHG",
  "_2": "_2",
  "VITALS": "VITALS",
  "KEEPS_TRACK_OF_VITAL_READINGS": "KEEPS_TRACK_OF_VITAL_READINGS",
  "NO_VITALS_YET": "NO_VITALS_YET",
  "GROUP": "GROUP",
  "_3": "_3",
  "ALL_DEVICES": "ALL_DEVICES",
  "TIME_RANGE": "TIME_RANGE",
  "LAST_HOURS": "LAST_HOURS",
  "LAST_WEEK": "LAST_WEEK",
  "LAST_WEEKS": "LAST_WEEKS",
  "LAST_WEEKS1": "LAST_WEEKS1",
  "LAST_MONTH": "LAST_MONTH",
  "LAST_MONTHS": "LAST_MONTHS",
  "LAST_QUARTER": "LAST_QUARTER",
  "LAST_MONTHS1": "LAST_MONTHS1",
  "LAST_YEAR": "LAST_YEAR",
  "LAST_YEARS": "LAST_YEARS",
  "ALL_DATA": "ALL_DATA",
  "ELECTRICITY_CHARGES": "ELECTRICITY_CHARGES",
  "CHARGES_PER_KWH": "CHARGES_PER_KWH",
  "_4": "_4",
  "FAILURE": "FAILURE",
  "DASHBOARD": "DASHBOARD",
  "RESIDENTS": "RESIDENTS",
  "ALERTS": "ALERTS",
  "SWITCH_PROFILE": "SWITCH_PROFILE",
  "MY_PROFILE": "MY_PROFILE",
  "LOGOUT": "LOGOUT",
  "LOADING": "LOADING",
  "SOMETHING_WENT_WRONG": "SOMETHING_WENT_WRONG",
  "_5": "_5",
  "GDS_SCORE": "GDS_SCORE",
  "OVER": "OVER",
  "OVER1": "OVER1",
  "OVER2": "OVER2",
  "OVER3": "OVER3",
  "MOVEMENT": "MOVEMENT",
  "NOT_TAKEN_IN_ACCOUNT": "NOT_TAKEN_IN_ACCOUNT",
  "ABOVE_AVERAGE": "ABOVE_AVERAGE",
  "AVERAGE": "AVERAGE",
  "BELOW_AVERAGE": "BELOW_AVERAGE",
  "PATHWAY_ENABLED": "PATHWAY_ENABLED",
  "ESCALATION": "ESCALATION",
  "PLEASE_CHOOSE_BELOW_THE_SETTIN": "PLEASE_CHOOSE_BELOW_THE_SETTIN",
  "FIRST_LEVEL": "FIRST_LEVEL",
  "NONE_DISABLED": "NONE_DISABLED",
  "INFORMAL_CARE": "INFORMAL_CARE",
  "FORMAL_CARE": "FORMAL_CARE",
  "LA_CASA_CARE": "LA_CASA_CARE",
  "HOUR": "HOUR",
  "HOURS": "HOURS",
  "HOURS1": "HOURS1",
  "HOURS2": "HOURS2",
  "HOURS3": "HOURS3",
  "DAY": "DAY",
  "DAYS": "DAYS",
  "DAYS1": "DAYS1",
  "DAYS2": "DAYS2",
  "DAYS3": "DAYS3",
  "SECOND_LEVEL": "SECOND_LEVEL",
  "THIRD_LEVEL_RESPONSE": "THIRD_LEVEL_RESPONSE",
  "THE_PATHWAY_CANNOT_BE_ENABLED": "THE_PATHWAY_CANNOT_BE_ENABLED",
  "NOTIFICATION": "NOTIFICATION",
  "WHEN_SPECIFIED_CONDITIONS_ARE": "WHEN_SPECIFIED_CONDITIONS_ARE",
  "VIA_PLATFORM_NOTIFICATION": "VIA_PLATFORM_NOTIFICATION",
  "VIA_SMS_SEND_TO": "VIA_SMS_SEND_TO",
  "ALL_INFORMAL_CAREGIVERS": "ALL_INFORMAL_CAREGIVERS",
  "ALL_FORMAL_CAREGIVERS": "ALL_FORMAL_CAREGIVERS",
  "VIA_EMAIL_SENT_ACCORDING_TO_I": "VIA_EMAIL_SENT_ACCORDING_TO_I",
  "ENABLED": "ENABLED",
  "_6": "_6",
  "LEVEL": "LEVEL",
  "DO_NOT_DETECT": "DO_NOT_DETECT",
  "EVEN_MINOR_ANOMALY": "EVEN_MINOR_ANOMALY",
  "MEDIUM_ANOMALY": "MEDIUM_ANOMALY",
  "MAJOR_ANOMALY_ONLY": "MAJOR_ANOMALY_ONLY",
  "SUCCESS": "SUCCESS",
  "REGISTER": "REGISTER",
  "REGISTER_LATER": "REGISTER_LATER",
  "SENSOR_REGISTRATION": "SENSOR_REGISTRATION",
  "THE_SYSTEM_USES_A_NUMBER_OF_SE": "THE_SYSTEM_USES_A_NUMBER_OF_SE",
  "YOU_MAY_REGISTER_SENSORS_NOW_O": "YOU_MAY_REGISTER_SENSORS_NOW_O",
  "WITH_THE_COMPANION_PLUS_VERSI": "WITH_THE_COMPANION_PLUS_VERSI",
  "SENSORS_MAY_BE_USED_FOR_AUTOMA": "SENSORS_MAY_BE_USED_FOR_AUTOMA",
  "COMPANION_PLUS": "COMPANION_PLUS",
  "PREVIOUS_STEP": "PREVIOUS_STEP",
  "NEXT_STEP": "NEXT_STEP",
  "TAKING_CARE_OF_IMPORTANT_EVENT": "TAKING_CARE_OF_IMPORTANT_EVENT",
  "CALENDAR": "CALENDAR",
  "THE_APP_HELPS_IMPROVE_THE_CARE": "THE_APP_HELPS_IMPROVE_THE_CARE",
  "WE_ENCOURAGE_YOU_TO_REGISTER_F": "WE_ENCOURAGE_YOU_TO_REGISTER_F",
  "THE_COMPANION_CONCEPT": "THE_COMPANION_CONCEPT",
  "TO_DISPLAY_LIST_OF_AVAILABLE_W": "TO_DISPLAY_LIST_OF_AVAILABLE_W",
  "CONNECT_NOW": "CONNECT_NOW",
  "POWERING_THE_SMARTHUB": "POWERING_THE_SMARTHUB",
  "THE_LA_CASA_COMPANION_APP_MAY": "THE_LA_CASA_COMPANION_APP_MAY",
  "USERS_WITHOUT_SENSORS": "USERS_WITHOUT_SENSORS",
  "SIMPLY_PROCEED_WITH_THE": "SIMPLY_PROCEED_WITH_THE",
  "NEXT_STEP1": "NEXT_STEP1",
  "BUTTON": "BUTTON",
  "FOR_USERS_WITH_THE_SMARTHUB_AN": "FOR_USERS_WITH_THE_SMARTHUB_AN",
  "I_HAVE_THE_HUB_AND_SENSORS": "I_HAVE_THE_HUB_AND_SENSORS",
  "MEMBERS_OF_COMPANION_MAY_PUBLI": "MEMBERS_OF_COMPANION_MAY_PUBLI",
  "NOTIFICATIONS_ARE_AUTOMATICALL": "NOTIFICATIONS_ARE_AUTOMATICALL",
  "DOCUMENTS": "DOCUMENTS",
  "WELCOME_TO_LA_CASA": "WELCOME_TO_LA_CASA",
  "THANK_YOU_FOR_DOWNLOADING_LA_C": "THANK_YOU_FOR_DOWNLOADING_LA_C",
  "THIS_IS_THE_WELCOME_WIZARD_FOR": "THIS_IS_THE_WELCOME_WIZARD_FOR",
  "TO_PROCEED_HIT_THE": "TO_PROCEED_HIT_THE",
  "START_THE_SETUP": "START_THE_SETUP",
  "BUTTON_BELOW": "BUTTON_BELOW",
  "LICENSE_AND_DATA_PRIVACY_AGREE": "LICENSE_AND_DATA_PRIVACY_AGREE",
  "TO_PROCEED_PLEASE_READ_AND_CO": "TO_PROCEED_PLEASE_READ_AND_CO",
  "I_HAVE_READ_AND_UNDERSTOOD_THE": "I_HAVE_READ_AND_UNDERSTOOD_THE",
  "CONTENTS": "CONTENTS",
  "WHO_ARE_WE": "WHO_ARE_WE",
  "WHY_WE_COLLECT_INFORMATION_ABO": "WHY_WE_COLLECT_INFORMATION_ABO",
  "THE_INFORMATION_WE_PROCESS_ABO": "THE_INFORMATION_WE_PROCESS_ABO",
  "WHY_WE_USE_YOUR_INFORMATION": "WHY_WE_USE_YOUR_INFORMATION",
  "HOW_LONG_WE_HOLD_YOUR_INFORMAT": "HOW_LONG_WE_HOLD_YOUR_INFORMAT",
  "PROCESSING_YOUR_INFORMATION_OU": "PROCESSING_YOUR_INFORMATION_OU",
  "WHAT_ARE_MY_RIGHTS_WHEN_YOU_PR": "WHAT_ARE_MY_RIGHTS_WHEN_YOU_PR",
  "CONTACT_US": "CONTACT_US",
  "LA_CASA_COMPANION_APP": "LA_CASA_COMPANION_APP",
  "WHO_ARE_WE1": "WHO_ARE_WE1",
  "FOR_THE_PURPOSES_OF_THE_GENERA": "FOR_THE_PURPOSES_OF_THE_GENERA",
  "VISITORS_TO_OUR_WEBSITES": "VISITORS_TO_OUR_WEBSITES",
  "INDIVIDUALS_WHO_WE_COMMUNICATE": "INDIVIDUALS_WHO_WE_COMMUNICATE",
  "CLIENTS_AND_OR_SERVICE_USERS": "CLIENTS_AND_OR_SERVICE_USERS",
  "INDIVIDUALS_NOMINATED_BY_A_CLI": "INDIVIDUALS_NOMINATED_BY_A_CLI",
  "NOTE": "NOTE",
  "THE_COMPANION_IS": "THE_COMPANION_IS",
  "INDIVIDUALS_WHOSE_PERSONAL_DAT": "INDIVIDUALS_WHOSE_PERSONAL_DAT",
  "BUSINESS_REFERRALS_PASSED_TO_U": "BUSINESS_REFERRALS_PASSED_TO_U",
  "EMPLOYEES": "EMPLOYEES",
  "OUR_WEBSITES_INCLUDES_THIS_W": "OUR_WEBSITES_INCLUDES_THIS_W",
  "WE_USE_OPTIONAL_COOKIES_TO_OPT": "WE_USE_OPTIONAL_COOKIES_TO_OPT",
  "WHY_WE_COLLECT_INFORMATION": "WHY_WE_COLLECT_INFORMATION",
  "IN_ORDER_TO_CARRY_OUT_OUR_BUSI": "IN_ORDER_TO_CARRY_OUT_OUR_BUSI",
  "TO_ENTER_INTO_TO_TAKE_STEPS_T": "TO_ENTER_INTO_TO_TAKE_STEPS_T",
  "TO_RESPOND_TO_ANY_ENQUIRES_FRO": "TO_RESPOND_TO_ANY_ENQUIRES_FRO",
  "TO_RESPOND_TO_ANY_ENQUIRIES": "TO_RESPOND_TO_ANY_ENQUIRIES",
  "TO_PROVIDE_WITH_CONSENT_MONI": "TO_PROVIDE_WITH_CONSENT_MONI",
  "TO_PROVIDE_ASSISTANCE_TO_A_CLI": "TO_PROVIDE_ASSISTANCE_TO_A_CLI",
  "TO_RESPOND_TO_EMERGENCY_ALARMS": "TO_RESPOND_TO_EMERGENCY_ALARMS",
  "TO_CONTACT_WITH_CONSENT_A_SE": "TO_CONTACT_WITH_CONSENT_A_SE",
  "TO_CONTACT_MEDICAL_PROFESSIONA": "TO_CONTACT_MEDICAL_PROFESSIONA",
  "TO_INVESTIGATE_ANY_SUSPECTED_O": "TO_INVESTIGATE_ANY_SUSPECTED_O",
  "TO_PREVENT_MONITOR_INVESTIGA": "TO_PREVENT_MONITOR_INVESTIGA",
  "TO_PROTECT_THE_VITAL_INTERESTS": "TO_PROTECT_THE_VITAL_INTERESTS",
  "TO_COMPLY_WITH_A_LEGAL_OBLIGAT": "TO_COMPLY_WITH_A_LEGAL_OBLIGAT",
  "FOR_THE_PURPOSE_OF_ANY_LEGITIM": "FOR_THE_PURPOSE_OF_ANY_LEGITIM",
  "THE_ABOVE_PURPOSES_ALSO_APPLY": "THE_ABOVE_PURPOSES_ALSO_APPLY",
  "PERSONAL_DATA_IS_COLLECTED_BY": "PERSONAL_DATA_IS_COLLECTED_BY",
  "INDIVIDUALS_PROVIDE_US_WITH_DA": "INDIVIDUALS_PROVIDE_US_WITH_DA",
  "WHEN_THEY_CONTACT_US_WITH_A_QU": "WHEN_THEY_CONTACT_US_WITH_A_QU",
  "WHEN_THEY_VISIT_OUR_OFFICES": "WHEN_THEY_VISIT_OUR_OFFICES",
  "WHEN_THEY_WRITE_EMAIL_OR_PHON": "WHEN_THEY_WRITE_EMAIL_OR_PHON",
  "WHEN_THEY_CONTACT_US_VIA_THE_L": "WHEN_THEY_CONTACT_US_VIA_THE_L",
  "WHEN_THEY_CONTACT_US_VIA_THE_L1": "WHEN_THEY_CONTACT_US_VIA_THE_L1",
  "WHEN_THEY_SIGN_UP_AS_A_CLIENT": "WHEN_THEY_SIGN_UP_AS_A_CLIENT",
  "WHEN_THEY_ENTER_INTO_A_CONTRAC": "WHEN_THEY_ENTER_INTO_A_CONTRAC",
  "WHEN_THEY_ENGAGE_WITH_THE_ONB": "WHEN_THEY_ENGAGE_WITH_THE_ONB",
  "WE_ALSO_RECEIVE_PERSONAL_INFOR": "WE_ALSO_RECEIVE_PERSONAL_INFOR",
  "VIA_A_REFERRER_WHO_WITH_CONSE": "VIA_A_REFERRER_WHO_WITH_CONSE",
  "VIA_A_REFERRER_WHO_WITH_CONSE1": "VIA_A_REFERRER_WHO_WITH_CONSE1",
  "CAREHUB_CUSTOMER_CARE": "CAREHUB_CUSTOMER_CARE",
  "RETAIL_MARKET_MONITORING_AND_R": "RETAIL_MARKET_MONITORING_AND_R",
  "WHERE_A_CLIENT_OR_EMPLOYEE_OF": "WHERE_A_CLIENT_OR_EMPLOYEE_OF",
  "THE_INFORMATION_WE_PROCESS": "THE_INFORMATION_WE_PROCESS",
  "IDENTITY_NAME_SURNAME_TITL": "IDENTITY_NAME_SURNAME_TITL",
  "DETAILS_PROVIDED_WHEN_YOU_CONT": "DETAILS_PROVIDED_WHEN_YOU_CONT",
  "CONTACT_INFORMATION_ADDRESS": "CONTACT_INFORMATION_ADDRESS",
  "COMPANION_AND_SUBSCRIBERS_PE": "COMPANION_AND_SUBSCRIBERS_PE",
  "DETAILS_OF_A_CLIENT_S_HEALTH_A": "DETAILS_OF_A_CLIENT_S_HEALTH_A",
  "SUPPLIER_DETAILS_CONTACT_INF": "SUPPLIER_DETAILS_CONTACT_INF",
  "STAKEHOLDER_DETAILS_CONTACT": "STAKEHOLDER_DETAILS_CONTACT",
  "LICENCE_AUTHORISATION_APPLIC": "LICENCE_AUTHORISATION_APPLIC",
  "WEBSITE_USER_STATISTICS_GOOGL": "WEBSITE_USER_STATISTICS_GOOGL",
  "OTHER_PERSONAL_INFORMATION_I": "OTHER_PERSONAL_INFORMATION_I",
  "RECRUITMENT_AND_EMPLOYEE_DETAI": "RECRUITMENT_AND_EMPLOYEE_DETAI",
  "MARKETING_INFORMATION_IF_YOU": "MARKETING_INFORMATION_IF_YOU",
  "WHY_WE_USE_YOUR_INFORMATION1": "WHY_WE_USE_YOUR_INFORMATION1",
  "IN_ADDITION_TO_THE_ABOVE_WE_O": "IN_ADDITION_TO_THE_ABOVE_WE_O",
  "TO_ENTER_INTO_A_CONTRACT_OR_T": "TO_ENTER_INTO_A_CONTRACT_OR_T",
  "TO_PROVIDE_INFORMATION_TO_PROS": "TO_PROVIDE_INFORMATION_TO_PROS",
  "TO_PROVIDE_OUR_PRODUCTS_AND_SE": "TO_PROVIDE_OUR_PRODUCTS_AND_SE",
  "TO_PROVIDE_FOLLOW_UP_SERVICES": "TO_PROVIDE_FOLLOW_UP_SERVICES",
  "TO_ENSURE_PAYMENT_TO_LA_CASA_O": "TO_ENSURE_PAYMENT_TO_LA_CASA_O",
  "ENGAGING_SERVICE_PROVIDERS_AND": "ENGAGING_SERVICE_PROVIDERS_AND",
  "FILLING_VACANT_POSITIONS_AND_F": "FILLING_VACANT_POSITIONS_AND_F",
  "TO_RUN_OUR_ORGANISATION_ON_A_D": "TO_RUN_OUR_ORGANISATION_ON_A_D",
  "CONTACT_STAKEHOLDERS_BUSINESS": "CONTACT_STAKEHOLDERS_BUSINESS",
  "COMPILE_AND_PROCESS_YOUR_INFOR": "COMPILE_AND_PROCESS_YOUR_INFOR",
  "PROTECT_OUR_BUSINESS_REPUTATI": "PROTECT_OUR_BUSINESS_REPUTATI",
  "MANAGE_AND_ADMINISTER_OUR_LEGA": "MANAGE_AND_ADMINISTER_OUR_LEGA",
  "MANAGING_AND_RESPONDING_TO_COM": "MANAGING_AND_RESPONDING_TO_COM",
  "EFFECTIVELY_MANAGE_THE_FUNCTIO": "EFFECTIVELY_MANAGE_THE_FUNCTIO",
  "WE_WILL_ONLY_PROCESS_YOUR_PERS": "WE_WILL_ONLY_PROCESS_YOUR_PERS",
  "TRANSFERRING_THE_PERSONAL_DATA": "TRANSFERRING_THE_PERSONAL_DATA",
  "CONDUCTING_INTERNAL_AUDITS_OR": "CONDUCTING_INTERNAL_AUDITS_OR",
  "IMPLEMENTING_BUSINESS_CONTROLS": "IMPLEMENTING_BUSINESS_CONTROLS",
  "CONDUCTING_STATISTICAL_ANALYSI": "CONDUCTING_STATISTICAL_ANALYSI",
  "PREPARING_FOR_OR_ENGAGING_IN_D": "PREPARING_FOR_OR_ENGAGING_IN_D",
  "USING_LEGAL_OR_BUSINESS_CONSUL": "USING_LEGAL_OR_BUSINESS_CONSUL",
  "MANAGING_INSURANCE_ISSUES": "MANAGING_INSURANCE_ISSUES",
  "TO_PROTECT_THE_VITAL_INTERESTS1": "TO_PROTECT_THE_VITAL_INTERESTS1",
  "IN_ORDER_TO_PROTECT_THE_HEALTH": "IN_ORDER_TO_PROTECT_THE_HEALTH",
  "TO_CONTACT_EMERGENCY_SERVICES": "TO_CONTACT_EMERGENCY_SERVICES",
  "TO_REPORT_ANY_SUSPECTED_CRIMIN": "TO_REPORT_ANY_SUSPECTED_CRIMIN",
  "TO_INITIATE_CONTINUE_WITH_OR": "TO_INITIATE_CONTINUE_WITH_OR",
  "TO_OBTAIN_LEGAL_ADVICE_ON_A_SP": "TO_OBTAIN_LEGAL_ADVICE_ON_A_SP",
  "TO_INVESTIGATE_MONITOR_OR_PRE": "TO_INVESTIGATE_MONITOR_OR_PRE",
  "COMPLYING_WITH_OUR_LEGAL_OBLIG": "COMPLYING_WITH_OUR_LEGAL_OBLIG",
  "SPECIAL_CATEGORIES_OF_PERSONAL": "SPECIAL_CATEGORIES_OF_PERSONAL",
  "WE_PROCESS_SPECIAL_CATEGORIES": "WE_PROCESS_SPECIAL_CATEGORIES",
  "EXPLICIT_CONSENT_OF_THE_DATA_S": "EXPLICIT_CONSENT_OF_THE_DATA_S",
  "EXPLICIT_CONSENT_OF_THE_DATA_S1": "EXPLICIT_CONSENT_OF_THE_DATA_S1",
  "WHERE_PROCESSING_IS_NECESSARY": "WHERE_PROCESSING_IS_NECESSARY",
  "FOR_THE_ESTABLISHMENT_EXERCIS": "FOR_THE_ESTABLISHMENT_EXERCIS",
  "WHERE_PROCESSING_IS_NECESSARY1": "WHERE_PROCESSING_IS_NECESSARY1",
  "WHO_WE_SHARE_YOUR_INFORMATI": "WHO_WE_SHARE_YOUR_INFORMATI",
  "WE_ONLY_SHARE_YOUR_INFORMATION": "WE_ONLY_SHARE_YOUR_INFORMATION",
  "A_CLIENT_S_COMPANION_A_CLIENT": "A_CLIENT_S_COMPANION_A_CLIENT",
  "EMERGENCY_SERVICES_OR_MEDICAL": "EMERGENCY_SERVICES_OR_MEDICAL",
  "MANDATED_BODIES_UNDER_STATUTE": "MANDATED_BODIES_UNDER_STATUTE",
  "DATA_PROCESSORS_WHO_ARE_THIRD": "DATA_PROCESSORS_WHO_ARE_THIRD",
  "WEBSITE_ANALYTICS_WHEN_YOU_VI": "WEBSITE_ANALYTICS_WHEN_YOU_VI",
  "WE_WILL_KEEP_YOUR_PERSONAL_DAT": "WE_WILL_KEEP_YOUR_PERSONAL_DAT",
  "THE_TYPE_OF_DATA_WE_HOLD_ABOUT": "THE_TYPE_OF_DATA_WE_HOLD_ABOUT",
  "WHETHER_THERE_IS_A_LEGAL_OBLIG": "WHETHER_THERE_IS_A_LEGAL_OBLIG",
  "WHERE_THERE_IS_A_SOUND_EVIDENC": "WHERE_THERE_IS_A_SOUND_EVIDENC",
  "LA_CASA_HAS_A_PERSONAL_DATA_RE": "LA_CASA_HAS_A_PERSONAL_DATA_RE",
  "PROCESSING_YOUR_INFORMATION": "PROCESSING_YOUR_INFORMATION",
  "WE_STORE_YOUR_DATA_ON_EU_BASED": "WE_STORE_YOUR_DATA_ON_EU_BASED",
  "FOR_MORE_INFORMATION_ON_THE_DA": "FOR_MORE_INFORMATION_ON_THE_DA",
  "WHAT_ARE_MY_RIGHTS_WHEN_YOU": "WHAT_ARE_MY_RIGHTS_WHEN_YOU",
  "YOU_HAVE_A_NUMBER_OF_RIGHTS_IN": "YOU_HAVE_A_NUMBER_OF_RIGHTS_IN",
  "LA_CASA_DOES_NOT_ENGAGE_IN_ANY": "LA_CASA_DOES_NOT_ENGAGE_IN_ANY",
  "IN_ORDER_TO_REQUEST_YOUR_PERSO": "IN_ORDER_TO_REQUEST_YOUR_PERSO",
  "INFO_LACASA_CARE": "INFO_LACASA_CARE",
  "YOU_ALSO_HAVE_THE_RIGHT_TO_COM": "YOU_ALSO_HAVE_THE_RIGHT_TO_COM",
  "WWW_DATAPROTECTION_IE": "WWW_DATAPROTECTION_IE",
  "OR": "OR",
  "DATA_PROTECTION_COMMISSION": "DATA_PROTECTION_COMMISSION",
  "FITZWILLIAM_SQUARE": "FITZWILLIAM_SQUARE",
  "DUBLIN": "DUBLIN",
  "D_RD": "D_RD",
  "PLEASE_CONTACT_US_IN_THE_FIRST": "PLEASE_CONTACT_US_IN_THE_FIRST",
  "CONTACT_US1": "CONTACT_US1",
  "THE_PRIMARY_POINT_OF_CONTACT_F": "THE_PRIMARY_POINT_OF_CONTACT_F",
  "FOR_THE_ATTENTION_OF_THE_DATA": "FOR_THE_ATTENTION_OF_THE_DATA",
  "THE_COMPANION_APP": "THE_COMPANION_APP",
  "THE_LA_CASA_COMPANION_APP_ALS": "THE_LA_CASA_COMPANION_APP_ALS",
  "AS_PART_OF_ENTERING_INTO_ANY_A": "AS_PART_OF_ENTERING_INTO_ANY_A",
  "THE_LA_CASA_COMPANION_APP_NEED": "THE_LA_CASA_COMPANION_APP_NEED",
  "ALLOW_THE_LA_CASA_COMPANION_AP": "ALLOW_THE_LA_CASA_COMPANION_AP",
  "ALLOW_THE_LA_CASA_COMPANION_AP1": "ALLOW_THE_LA_CASA_COMPANION_AP1",
  "ALLOW_THE_LA_CASA_COMPANION_AP2": "ALLOW_THE_LA_CASA_COMPANION_AP2",
  "ALLOW_THE_LA_CASA_COMPANION_AP3": "ALLOW_THE_LA_CASA_COMPANION_AP3",
  "ALLOW_THE_LA_CASA_COMPANION_AP4": "ALLOW_THE_LA_CASA_COMPANION_AP4",
  "THE_REASON_THE_LA_CASA_COMPANI": "THE_REASON_THE_LA_CASA_COMPANI",
  "ACCESS_AND_USE_THE_LA_CASA_COM": "ACCESS_AND_USE_THE_LA_CASA_COM",
  "NOTIFY_THE_USER_OF_ANY_ACTIVIT": "NOTIFY_THE_USER_OF_ANY_ACTIVIT",
  "MAKE_AND_RECEIVE_AUDIO_VIDEO": "MAKE_AND_RECEIVE_AUDIO_VIDEO",
  "SEND_AND_RECEIVE_MEDIA_PHOTOS": "SEND_AND_RECEIVE_MEDIA_PHOTOS",
  "UPON_INSTALLATION_OF_THE_LA_CA": "UPON_INSTALLATION_OF_THE_LA_CA",
  "COOKIES_USED_ON_THE_LA_CASA_CO": "COOKIES_USED_ON_THE_LA_CASA_CO",
  "THE_LA_CASA_COMPANION_APP_DOES": "THE_LA_CASA_COMPANION_APP_DOES",
  "LAST_REVIEW_NOVEMBER": "LAST_REVIEW_NOVEMBER",
  "THIS_POLICY_MAY_BE_UPDATED_WIT": "THIS_POLICY_MAY_BE_UPDATED_WIT",
  "THIS_IS_LA_CASA_S_PRIVACY_POLI": "THIS_IS_LA_CASA_S_PRIVACY_POLI",
  "LA_CASA_IS_COMMITTED_TO_PROTEC": "LA_CASA_IS_COMMITTED_TO_PROTEC",
  "KEEP_ON_TRACK_OF_MEDICATION_W": "KEEP_ON_TRACK_OF_MEDICATION_W",
  "TO_GET_STARTED_SIMPLY_ENTER_T": "TO_GET_STARTED_SIMPLY_ENTER_T",
  "MEDICATION": "MEDICATION",
  "EXCHANGING_SECURE_MESSAGES_WIT": "EXCHANGING_SECURE_MESSAGES_WIT",
  "THE_MESSAGING_FUNCTION_KEEPS_A": "THE_MESSAGING_FUNCTION_KEEPS_A",
  "MESSAGING": "MESSAGING",
  "REGISTRATION_COMPLETE": "REGISTRATION_COMPLETE",
  "THANK_YOU_FOR_JOINING_LA_CASA": "THANK_YOU_FOR_JOINING_LA_CASA",
  "SOME_USEFUL_LINKS": "SOME_USEFUL_LINKS",
  "SERVICE_HOMEPAGE": "SERVICE_HOMEPAGE",
  "BRIEF_INTRODUCTION_TO_THE_PLAT": "BRIEF_INTRODUCTION_TO_THE_PLAT",
  "PRODUCT_BLOG": "PRODUCT_BLOG",
  "YOU_MAY_VISIT": "YOU_MAY_VISIT",
  "OUR_TWITTER": "OUR_TWITTER",
  "FOR_UPDATES_OR_EMAIL": "FOR_UPDATES_OR_EMAIL",
  "START_USING_THE_APPLICATION": "START_USING_THE_APPLICATION",
  "THERE_IS_NO_HARDWARE_ATTACHED": "THERE_IS_NO_HARDWARE_ATTACHED",
  "OUT_OF_HOME": "OUT_OF_HOME",
  "RARELY_LESS_THAN": "RARELY_LESS_THAN",
  "LAST_DOOR_EVENT": "LAST_DOOR_EVENT",
  "HOURS_AGO": "HOURS_AGO",
  "APPLIANCE_USAGE": "APPLIANCE_USAGE",
  "AS_USUAL": "AS_USUAL",
  "MINUTES_AGO": "MINUTES_AGO",
  "RESTING_TIME": "RESTING_TIME",
  "LONG_OVER_HOURS_DAILY": "LONG_OVER_HOURS_DAILY",
  "WATCHING_TV": "WATCHING_TV",
  "FREQUENTLY_OVER": "FREQUENTLY_OVER",
  "VISITS": "VISITS",
  "MANY_MORE_THAN_WEEKLY": "MANY_MORE_THAN_WEEKLY",
  "LAUNDRY": "LAUNDRY",
  "RARELY_LESS_THAN_MONTHLY": "RARELY_LESS_THAN_MONTHLY",
  "BATHROOM_VISITS": "BATHROOM_VISITS",
  "NORMAL_BELOW_VISITS": "NORMAL_BELOW_VISITS",
  "NO_NEW_INSIGHTS": "NO_NEW_INSIGHTS",
  "NO_APPOINTMENTS": "NO_APPOINTMENTS",
  "THERE_ARE": "THERE_ARE",
  "UPCOMING_APPOINTMENTS": "UPCOMING_APPOINTMENTS",
  "HEADER": "HEADER",
  "CONTENTS1": "CONTENTS1",
  "YOU_HAVE": "YOU_HAVE",
  "CONTACTS": "CONTACTS",
  "OF_THEM_AWAITING_ACCEPTANCE": "OF_THEM_AWAITING_ACCEPTANCE",
  "OVERALL_REPORT": "OVERALL_REPORT",
  "NO_DEVICES_MATCHING_YOUR_CRITE": "NO_DEVICES_MATCHING_YOUR_CRITE",
  "DASHBOARD_DEVICE": "DASHBOARD_DEVICE",
  "OVERALL": "OVERALL",
  "WARNING": "WARNING",
  "NO_DOCUMENTS": "NO_DOCUMENTS",
  "NO_NEW_DOCUMENTS": "NO_NEW_DOCUMENTS",
  "DOCUMENTS_TOTAL": "DOCUMENTS_TOTAL",
  "NEW_DOCUMENTS": "NEW_DOCUMENTS",
  "NO_MEDICATION": "NO_MEDICATION",
  "ACTIVE_MEDICATIONS": "ACTIVE_MEDICATIONS",
  "THERE_IS_NO_HARDWARE_ATTACHED1": "THERE_IS_NO_HARDWARE_ATTACHED1",
  "ROOM_TEMPERATURE": "ROOM_TEMPERATURE",
  "C": "C",
  "ROOM_HUMIDITY": "ROOM_HUMIDITY",
  "_7": "_7",
  "ENERGY_USAGE": "ENERGY_USAGE",
  "KWH_TOTAL": "KWH_TOTAL",
  "BLOOD_PRESSURE": "BLOOD_PRESSURE",
  "_8": "_8",
  "BMI": "BMI",
  "N_A": "N_A",
  "OXYGEN_SATURATION": "OXYGEN_SATURATION",
  "_9": "_9",
  "SLEEPING": "SLEEPING",
  "H": "H",
  "SLEEPING_QUALITY": "SLEEPING_QUALITY",
  "GOOD": "GOOD",
  "BLOOD_GLUCOSE": "BLOOD_GLUCOSE",
  "CHOLESTEROL_LEVEL": "CHOLESTEROL_LEVEL",
  "HEART_ARYTHMIA": "HEART_ARYTHMIA",
  "PAIN": "PAIN",
  "NONE": "NONE",
  "THERE_ARE_NO_DEVICES_YET": "THERE_ARE_NO_DEVICES_YET",
  "THE_FEATURE_IS_UNAVAILABLE_CO": "THE_FEATURE_IS_UNAVAILABLE_CO",
  "IMPROVE_SOCIAL_CONTACTS": "IMPROVE_SOCIAL_CONTACTS",
  "RECOMMENDATIONS": "RECOMMENDATIONS",
  "INVITE_NEW_CONTACT": "INVITE_NEW_CONTACT",
  "CONNECT_THE_HUB": "CONNECT_THE_HUB",
  "ADD_SENSOR": "ADD_SENSOR",
  "VITALS1": "VITALS1",
  "NEMINI_LICET_HIC": "NEMINI_LICET_HIC",
  "HIC_SUNT_LEONES": "HIC_SUNT_LEONES",
  "LICENSE": "LICENSE",
  "THERE_IS_A_PREMIUM_OPTION_OF_T": "THERE_IS_A_PREMIUM_OPTION_OF_T",
  "LA_CASA": "LA_CASA",
  "LICENSE_TYPE": "LICENSE_TYPE",
  "ORGANIZATION": "ORGANIZATION",
  "THE_USER_IS_REGISTERED_UNDER_T": "THE_USER_IS_REGISTERED_UNDER_T",
  "ORGANIZATION1": "ORGANIZATION1"
};

export default keys;
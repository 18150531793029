import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Loading } from "./components/Loading";
import { isIOS } from "./utils/Mobile";

// @ts-ignore
export function PrivateRoute({component, ...args}) {

  const Redirecting = () => <div className="text-center"><Loading text="Redirecting..."/></div>;

  const comp = isIOS() ? component : withAuthenticationRequired(component, {onRedirecting: () => <Redirecting/>});
  return <Route component={comp} {...args} />;
}
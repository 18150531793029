import i18next from 'i18next';

import english from './english';

i18next.init({
  lng: localStorage.getItem('lng') || 'en',
  debug: true,
  resources: {
    en: {translation: english}
  },
});

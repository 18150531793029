export const HOUR = 1000 * 60 * 60;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const BIWEEK = WEEK * 2;
export const TRIWEEK = WEEK * 3;
export const MONTH = 30 * DAY;
export const BIMONTH = MONTH * 2;
export const QUARTER = 3 * MONTH;
export const HALFYEAR = MONTH * 6;
export const YEAR = 365 * DAY;
export const BIYEAR = YEAR * 2;
export const EVERYTHING = YEAR * 5;
import { ContactRole } from "./Contact";

export enum ReadoutType {
  BLOOD_PRESSURE = "blood-pressure",
  BMI = "bmi",
  OXYGEN_SATURATION = "oxygen-saturation",
  SLEEPING = "sleeping",
  SLEEPING_QUALITY = "sleeping-quality",
  BLOOD_GLUCOSE = "blood-glucose",
  CHOLESTEROL_LEVEL = "cholesterol-level",
  HEART_ARYTHMIA = "heart-arythmia",
  PAIN = "pain",
  MOOD = "mood"
}

enum ReadoutFrequncy {
  HOURLY = 0, DAILY, WEEKLY, BIWEEKLY, TRIWEEKLY, MONTHLY, BIMONTHLY, QUARTERLY
}

enum ReadoutStatus {
  PENDING = "pending", SUCCESS = "success", TIMEOUT = "timeout", CANCELLED = "cancelled"
}

interface ReadoutExecution {
  readoutType: string
  date: string | Date
  role: ContactRole
  status: ReadoutStatus
}

export interface Readout {
  type: ReadoutType
  initialRole: ContactRole
  frequency: ReadoutFrequncy
}

export interface ReadoutPlan {
  id: string
  name: string,
  description: string,
  active: boolean,
  readouts: Array<Readout>,
  executions: Array<ReadoutExecution>
}



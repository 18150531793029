import { Insight } from "./Insight";
import { Alert } from "./Alert";
import { ReadoutPlan } from "./Readout";

export interface DeviceAccess {
  sub: string
  org?: string
  role?: string
}

export interface Resident {
  name: string
  phone: string
  email: string
  consent: string
}

interface Property {
  address?: string
  eircode?: string
  location?: Location
}

interface Location {
  lat: number
  lng: number
}

export enum SensorType {
  TEMPERATURE = 1,
  HUMIDITY,
  PRESSURE,
  PIR,
  TRIGGERED,
  DOOR,
  ENERGY_CLAMP,
  HEARTRATE,
  STEPS,
  METERS,
  FAT,
  CALLORIES,
  WIFI_SENSING
}

export interface SensorConfig {
  type: SensorType
  txInterval?: number
  address?: string
  notes?: string
}

interface Group {
  name: String
}

export interface SensorExt extends SensorConfig {
  idx: number
}

export interface ValueBase {
  timestamp: string
  value: number
}

export interface Value extends ValueBase {
  sensor: string
  precision: string
  value: number
  count: number
  detail: Record<string, any>
}

export interface WifiSensor {
  username: string,
  password: string,
  homeId: string
}

export interface Device {
  id: string
  access: Array<DeviceAccess>
  group?: Group
  resident?: Resident
  property?: Property
  sensors?: Array<SensorConfig>
  lastValue?: string,
  lastAnomaly?: Date,
  lastAlert?: Date,
  values?: Record<string, Array<Value>>
  insights?: Array<Insight>
  alerts?: Array<Alert>
  readoutPlan?: ReadoutPlan
  wifiSensor?: WifiSensor
}

import { AccessRole, Auth, Device, DeviceAccess, UserRole } from "../types";

export function isOwner(auth?: Auth, device?: Device) {
  return device?.access.find((a: DeviceAccess) => a.sub === auth?.sub && a.role === AccessRole.OWNER);
}

export function isOwnerOrEditor(auth?: Auth, device?: Device) {
  return device?.access.find((a: DeviceAccess) => a.sub === auth?.sub && (a.role === AccessRole.OWNER || a.role === AccessRole.EDITOR));
}

export function isOrgMember(auth?: Auth, device?: Device): boolean {
  const hasAccess = (device: Device) => device.access.find((a: DeviceAccess) => a.org === auth?.org) !== undefined;
  return auth?.org !== undefined && (device ? hasAccess(device) : true);
}

export function isOrgAdmin(auth?: Auth, device?: Device): boolean {
  const isOrgAdmin: boolean = auth !== undefined && auth.roles.includes(UserRole.ORG_ADMIN);
  const hasOrg: boolean = auth?.org !== undefined;
  const hasAccess = (device: Device) => device.access.find((a: DeviceAccess) => a.org === auth?.org) !== undefined;
  return isOrgAdmin && hasOrg && (device ? hasAccess(device) : true);
}

export function isAdmin(auth?: Auth) {
  auth?.roles.includes(UserRole.ADMIN);
}
import { ReactNode, useContext, useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import { SafeArea } from 'capacitor-plugin-safe-area';

import { AuthContext } from "../../contexts/AuthContext";
import { isIOS } from "../../utils/Mobile";
import styled from "styled-components";

interface SidePaneType {
  isOpen: boolean
  onRequestClose: () => void
  children?: ReactNode
}

const StyledSlidingPane = styled(SlidingPane)<{ marginTop: string, height: string }>`
  margin-top: ${props => props.marginTop};
  height: ${props => props.height};
`

export function SidePane({isOpen, onRequestClose, children}: SidePaneType) {

  const {userPrefs} = useContext(AuthContext)
  const [insets, setInsets] = useState({top: 0, bottom: 0})

  useEffect(() => {
    if (isIOS()) SafeArea.getSafeAreaInsets().then(values => setInsets(values.insets))
  }, [])

  const width = window.innerWidth > 768 ? "550px" : "80%"
  const mt = `${insets.top + 50}px`
  const height = `calc(100vh - ${insets.top + 50}px)`

  return (
      <>
        {userPrefs?.detailAnimation &&
          <StyledSlidingPane hideHeader={true} width={width} marginTop={mt} height={height} isOpen={isOpen}
                             onRequestClose={onRequestClose}>{children}</StyledSlidingPane>}
        {!userPrefs?.detailAnimation &&
          <StyledSlidingPane hideHeader={true} width={width} marginTop={mt} height={height} isOpen={isOpen}
                             onRequestClose={onRequestClose} className="fast-transition">{children}</StyledSlidingPane>}
      </>
  )
}

export default SidePane;

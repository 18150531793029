import k from './keys';

const values = {
  [k._]: ``,
  [k.THE_HUB_IS_NOW_CONNECTED_TO_TH]: `The hub is now connected to the internet.`,
  [k.YOU_MAY_PROCEED_WITH_ADDING_SO]: `You may proceed to add sensors .`,
  [k.CONTINUE]: `Continue`,
  [k.THERE_WERE_AN_ERROR_WHILE_CONN]: `There were an error connecting to the internet.`,
  [k.PLEASE_MAKE_SURE_THE_WIFI_IS_A]: `Please make sure the Wifi is available and unrestricted.`,
  [k.RETRY]: `Retry`,
  [k.THERE_WERE_ANOMALIES]: `there were anomalies`,
  [k.NORMAL]: `normal`,
  [k.ACTIVITY]: `Activity`,
  [k.CARE_RECIPIENT_S_ACTIVITY]: `Care recipient activity.`,
  [k._1]: `(`,
  [k.EVENTS]: `events)`,
  [k.SMALL]: `small`,
  [k.PX]: `14px`,
  [k.INSIGHTS]: `Insights`,
  [k.IMPORTANT_FINDINGS_RELATED_TO]: `Important findings related to the care recipient.`,
  [k.THIS_FEATURE_IS_UNAVAILABLE]: `This feature is unavailable.`,
  [k.CONTACT_THE]: `Contact the`,
  [k.LA_CASA_SALES]: `La Casa sales`,
  [k.FOR_FURTHER_ASSISTANCE]: `for further assistance.`,
  [k.LONG_TIME_AGO]: `long time ago`,
  [k.ENERGY_CLAMP]: `energy-clamp`,
  [k.METRICS]: `Metrics`,
  [k.VALUES_SHOW_AVERAGE_VALUES_FOR]: `Values show average values for the selected period.`,
  [k.MOOD]: `Mood`,
  [k.KEEPS_TRACK_OF_MOOD_CHANGES]: `Keeps track of changes in mood.`,
  [k.THIS_FEATURE_REQUIRES_LA_CASA]: `This feature requires La Casa hardware to be present.`,
  [k.PLEASE_CONTACT_THE]: `Please contact the`,
  [k.FOR_FURTHER_ASSISTANCE_OR_NAVI]: `for further assistance or navigate to the`,
  [k.SETTINGS]: `Settings`,
  [k.SECTION_IF_YOU_ARE_EQUIP]: `section if you are equipped with the hub already and need to add sensors.`,
  [k.ACTIVE]: `ACTIVE`,
  [k.SINCE_AUG]: `Since: Aug 2024`,
  [k.INFORMAL_CAREGIVERS_ARE_REMIND]: `informal caregivers are reminded to contact the care recipient on a random basis, every day`,
  [k.FORMAL_CAREGIVERS_ARE_INFORMED]: `formal caregivers are informed about the actual state of the care recipient and they're visiting her more often, if possible`,
  [k.THERE_ARE_PHYSICAL_ACTIVITIES]: `there are recommended physical activities for the care recipient via notifications, every day`,
  [k.NUTRITION_SPECIALIST_IS_CONTAC]: `a nutrition specialist contacts the care recipient once a while, as eating well helps with recovery`,
  [k.THE_CARE_RECIPIENT_IS_WARNED_A]: `the care recipient is warned about negative implications of alcohol, caffeine, nicotine, cannabis, and other drugs, once a while`,
  [k.INTERVENTION_PATHWAYS]: `Intervention pathways`,
  [k.KEEPS_TRACK_OF_THE_HEALING]: `Keeps track of recovery.`,
  [k.MMHG]: `mmHg)`,
  [k._2]: `)`,
  [k.VITALS]: `Vitals`,
  [k.KEEPS_TRACK_OF_VITAL_READINGS]: `Keeps track of vital readings, both measured and reported.`,
  [k.NO_VITALS_YET]: `No vitals yet.`,
  [k.GROUP]: `Group:`,
  [k._3]: `-`,
  [k.ALL_DEVICES]: `All devices`,
  [k.TIME_RANGE]: `Time range:`,
  [k.LAST_HOURS]: `Last 24 hours`,
  [k.LAST_WEEK]: `Last week`,
  [k.LAST_WEEKS]: `Last 2 weeks`,
  [k.LAST_WEEKS1]: `Last 3 weeks`,
  [k.LAST_MONTH]: `Last month`,
  [k.LAST_MONTHS]: `Last 2 months`,
  [k.LAST_QUARTER]: `Last quarter`,
  [k.LAST_MONTHS1]: `Last 6 months`,
  [k.LAST_YEAR]: `Last year`,
  [k.LAST_YEARS]: `Last 2 years`,
  [k.ALL_DATA]: `All data`,
  [k.ELECTRICITY_CHARGES]: `Electricity charges`,
  [k.CHARGES_PER_KWH]: `Charges per kWh:`,
  [k._4]: `€`,
  [k.FAILURE]: `Failure.`,
  [k.DASHBOARD]: `Dashboard`,
  [k.RESIDENTS]: `Residents`,
  [k.ALERTS]: `Alerts`,
  [k.SWITCH_PROFILE]: `switch-profile-`,
  [k.MY_PROFILE]: `My Profile`,
  [k.LOGOUT]: `Logout`,
  [k.LOADING]: `Loading...`,
  [k.SOMETHING_WENT_WRONG]: `something went wrong`,
  [k._5]: `.`,
  [k.GDS_SCORE]: `GDS score:`,
  [k.OVER]: `Over 5`,
  [k.OVER1]: `Over 7`,
  [k.OVER2]: `Over 10`,
  [k.OVER3]: `Over 12`,
  [k.MOVEMENT]: `Movement:`,
  [k.NOT_TAKEN_IN_ACCOUNT]: `Not taken in account`,
  [k.ABOVE_AVERAGE]: `Above average`,
  [k.AVERAGE]: `Average`,
  [k.BELOW_AVERAGE]: `Below average`,
  [k.PATHWAY_ENABLED]: `pathway enabled`,
  [k.ESCALATION]: `Escalation`,
  [k.PLEASE_CHOOSE_BELOW_THE_SETTIN]: `Please choose below the settings that define how alerts are managed, i.e. who should be notified first and the time period for the notification to be sent to each level.`,
  [k.FIRST_LEVEL]: `First level:`,
  [k.NONE_DISABLED]: `None (disabled)`,
  [k.INFORMAL_CARE]: `Informal care`,
  [k.FORMAL_CARE]: `Formal care`,
  [k.LA_CASA_CARE]: `La Casa Care`,
  [k.HOUR]: `1 hour`,
  [k.HOURS]: `2 hours`,
  [k.HOURS1]: `4 hours`,
  [k.HOURS2]: `8 hours`,
  [k.HOURS3]: `12 hours`,
  [k.DAY]: `1 day`,
  [k.DAYS]: `2 days`,
  [k.DAYS1]: `3 days`,
  [k.DAYS2]: `4 days`,
  [k.DAYS3]: `5 days`,
  [k.SECOND_LEVEL]: `Second level:`,
  [k.THIRD_LEVEL_RESPONSE]: `Third level response:`,
  [k.THE_PATHWAY_CANNOT_BE_ENABLED]: `The pathway cannot be enabled as you have no  hardware device registered.`,
  [k.NOTIFICATION]: `Notification`,
  [k.WHEN_SPECIFIED_CONDITIONS_ARE]: `When specified conditions are met, the system notifies all respective users via selected channels.`,
  [k.VIA_PLATFORM_NOTIFICATION]: `via platform notification`,
  [k.VIA_SMS_SEND_TO]: `via SMS, send to`,
  [k.ALL_INFORMAL_CAREGIVERS]: `all informal caregivers`,
  [k.ALL_FORMAL_CAREGIVERS]: `all formal caregivers`,
  [k.VIA_EMAIL_SENT_ACCORDING_TO_I]: `via email (sent according to individual user's settings)`,
  [k.ENABLED]: `-enabled`,
  [k._6]: `:`,
  [k.LEVEL]: `-level`,
  [k.DO_NOT_DETECT]: `do not detect`,
  [k.EVEN_MINOR_ANOMALY]: `even minor anomaly`,
  [k.MEDIUM_ANOMALY]: `medium anomaly`,
  [k.MAJOR_ANOMALY_ONLY]: `major anomaly only`,
  [k.SUCCESS]: `Success.`,
  [k.REGISTER]: `Register`,
  [k.REGISTER_LATER]: `Register later...`,
  [k.SENSOR_REGISTRATION]: `Sensor registration`,
  [k.THE_SYSTEM_USES_A_NUMBER_OF_SE]: `The system uses a number of sensors to gather data.`,
  [k.YOU_MAY_REGISTER_SENSORS_NOW_O]: `You may register sensors now or add them anytime later. If there is an error registering sensors, please make sure the sensor is visible and try again later.`,
  [k.WITH_THE_COMPANION_PLUS_VERSI]: `With the Companion Plus+ version, sensors are used to better support the service user, coordinate care and respond to pre-emptive alerts.`,
  [k.SENSORS_MAY_BE_USED_FOR_AUTOMA]: `Sensors may be used for automatic reporting of activities of daily living directly to the app. Notifications are individually configurable for each member within the app.`,
  [k.COMPANION_PLUS]: `Companion Plus+`,
  [k.PREVIOUS_STEP]: `Previous Step`,
  [k.NEXT_STEP]: `Next Step`,
  [k.TAKING_CARE_OF_IMPORTANT_EVENT]: `Taking care of important events and appointments is another useful feature and made a lot easier with the
    Shared Calendar feature. Reminders can also be created for any event based on a preferred communication method
    (choose an App "push" notification, SMS or email) of each App user.`,
  [k.CALENDAR]: `Calendar`,
  [k.THE_APP_HELPS_IMPROVE_THE_CARE]: `The app helps improve the care and wellbeing of the service user, making the role of caregivers easier by using
    the app for communication and collaboration within the "circle of care" for each service user.`,
  [k.WE_ENCOURAGE_YOU_TO_REGISTER_F]: `We encourage you to register family and friends in an informal care role while homecare providers or medical
    professionals should be registered as formal carers.`,
  [k.THE_COMPANION_CONCEPT]: `The Companion concept`,
  [k.TO_DISPLAY_LIST_OF_AVAILABLE_W]: `To display list of available wifi networks, please click the "Connect NOW" button and choose the "LACASA_HUB"
    (using Bluetooth). If the "LACASA_HUB" is not available or there is an error connecting to the wifi network,
    please make sure the hub is powered and a wifi network is available, then try again.`,
  [k.CONNECT_NOW]: `Connect NOW`,
  [k.POWERING_THE_SMARTHUB]: `Powering the smarthub`,
  [k.THE_LA_CASA_COMPANION_APP_MAY]: `The La Casa Companion App may be used with or without the smarthub and sensors.`,
  [k.USERS_WITHOUT_SENSORS]: `Users without sensors`,
  [k.SIMPLY_PROCEED_WITH_THE]: `simply proceed with the`,
  [k.NEXT_STEP1]: `Next step`,
  [k.BUTTON]: `button.`,
  [k.FOR_USERS_WITH_THE_SMARTHUB_AN]: `For users with the smarthub and sensors, first power up the hub. It requires an internet connection for data transmission.`,
  [k.I_HAVE_THE_HUB_AND_SENSORS]: `I have the hub and sensors`,
  [k.MEMBERS_OF_COMPANION_MAY_PUBLI]: `Members of Companion may publish and share documents in the app, such as care plans, bills, schedule of care visits, medical reports,test reports and other data.`,
  [k.NOTIFICATIONS_ARE_AUTOMATICALL]: `Notifications are automatically created for newly uploaded documents.`,
  [k.DOCUMENTS]: `Documents`,
  [k.WELCOME_TO_LA_CASA]: `Welcome to La Casa`,
  [k.THANK_YOU_FOR_DOWNLOADING_LA_C]: `Thank you for downloading La Casa Companion, that helps Caregivers and families support someone in need of support and care, referred to as the "service user".`,
  [k.THIS_IS_THE_WELCOME_WIZARD_FOR]: `This is the Welcome wizard for new app users. It will guide you through the setup process and show you all the important features of the application.`,
  [k.TO_PROCEED_HIT_THE]: `To proceed, hit the`,
  [k.START_THE_SETUP]: `Start the setup`,
  [k.BUTTON_BELOW]: `button below.`,
  [k.LICENSE_AND_DATA_PRIVACY_AGREE]: `License and data privacy agreement`,
  [k.TO_PROCEED_PLEASE_READ_AND_CO]: `To proceed, please read and confirm your agreement.`,
  [k.I_HAVE_READ_AND_UNDERSTOOD_THE]: `I have read and understood the agreement`,
  [k.CONTENTS]: `Contents:`,
  [k.WHO_ARE_WE]: `Who are we?`,
  [k.WHY_WE_COLLECT_INFORMATION_ABO]: `Why we collect information about you`,
  [k.THE_INFORMATION_WE_PROCESS_ABO]: `The information we process about you`,
  [k.WHY_WE_USE_YOUR_INFORMATION]: `Why we use your information`,
  [k.HOW_LONG_WE_HOLD_YOUR_INFORMAT]: `How long we hold your information and how it is secured?`,
  [k.PROCESSING_YOUR_INFORMATION_OU]: `Processing your information outside the EEA`,
  [k.WHAT_ARE_MY_RIGHTS_WHEN_YOU_PR]: `What are my rights when you process my data?`,
  [k.CONTACT_US]: `Contact us`,
  [k.LA_CASA_COMPANION_APP]: `La Casa Companion app`,
  [k.WHO_ARE_WE1]: `1. Who are we?`,
  [k.FOR_THE_PURPOSES_OF_THE_GENERA]: `For the purposes of the General Data Protection Regulation (EU) 2016/379 [‘GDPR’] and the Data Protection Act 2018,
    the data controller is La Casa, Nexus Building, Bellfield, Dublin 4, Ireland. This statement sets out how we,
    as a data controller, collect and process personal data about:`,
  [k.VISITORS_TO_OUR_WEBSITES]: `Visitors to our websites`,
  [k.INDIVIDUALS_WHO_WE_COMMUNICATE]: `Individuals who we communicate or interact with in the course of our business`,
  [k.CLIENTS_AND_OR_SERVICE_USERS]: `Clients and or service users`,
  [k.INDIVIDUALS_NOMINATED_BY_A_CLI]: `Individuals nominated by a client and or service user to be a part of their ‘Companion’ (e.g. emergency contacts/next-of-kin for a client or service user)`,
  [k.NOTE]: `Note:`,
  [k.THE_COMPANION_IS]: `The ‘Companion’
    is a group of the service user’s nominated contacts who are contacted (with their consent)
    by La Casa at specified events (e.g. in case of emergency, at a scheduled time, to provide assistance to a service user,
    to make contact with a service user, etc.) or to enable ongoing assistance and support to the service user.`,
  [k.INDIVIDUALS_WHOSE_PERSONAL_DAT]: `Individuals whose personal data is provided to us in connection with the provision of our Services`,
  [k.BUSINESS_REFERRALS_PASSED_TO_U]: `Business referrals passed to us (with the consent of the referred person)`,
  [k.EMPLOYEES]: `Employees`,
  [k.OUR_WEBSITES_INCLUDES_THIS_W]: `Our “websites” includes this website, all our associated sites (such as our blogs) and our social media pages.
    This statement also sets out information about data subject rights and our obligations under data protection law.`,
  [k.WE_USE_OPTIONAL_COOKIES_TO_OPT]: `We use optional cookies to optimise navigation of our website. We only set strictly necessary cookies by default
    and you can accept optional cookies if you wish. For more information, please see our Cookie Policy.`,
  [k.WHY_WE_COLLECT_INFORMATION]: `2. Why we collect information`,
  [k.IN_ORDER_TO_CARRY_OUT_OUR_BUSI]: `In order to carry out our business, we need to collect and use personal information about the individuals
    who come into contact with us. The personal information we process is provided to us directly by a client,
    service user or potential client or service use, or individuals referred to us with the consent of the referrer:`,
  [k.TO_ENTER_INTO_TO_TAKE_STEPS_T]: `To enter into, to take steps to enter into and or to process a request to enter into, a contractual relationship
    with La Casa (e.g. or services and or products).`,
  [k.TO_RESPOND_TO_ANY_ENQUIRES_FRO]: `To respond to any enquires from any client, prospective clients or authorised circle of care members.`,
  [k.TO_RESPOND_TO_ANY_ENQUIRIES]: `To respond to any enquiries.`,
  [k.TO_PROVIDE_WITH_CONSENT_MONI]: `To provide (with consent) monitoring and or remote home care to a service user.`,
  [k.TO_PROVIDE_ASSISTANCE_TO_A_CLI]: `To provide assistance to a client or service user.`,
  [k.TO_RESPOND_TO_EMERGENCY_ALARMS]: `To respond to emergency alarms or situations (in order to provide assistance).`,
  [k.TO_CONTACT_WITH_CONSENT_A_SE]: `To contact (with consent) a services users nominated Companion.`,
  [k.TO_CONTACT_MEDICAL_PROFESSIONA]: `To contact medical professionals and emergency services in the event an individual requires assistance.`,
  [k.TO_INVESTIGATE_ANY_SUSPECTED_O]: `To investigate any suspected or actual unauthorised use of our services and or products.`,
  [k.TO_PREVENT_MONITOR_INVESTIGA]: `To prevent, monitor, investigate and or report any suspected criminal activities that may impact on La Casa.`,
  [k.TO_PROTECT_THE_VITAL_INTERESTS]: `To protect the vital interests of user on a case-by-base basis.`,
  [k.TO_COMPLY_WITH_A_LEGAL_OBLIGAT]: `To comply with a legal obligation to which we are subject to, e.g. mandatory reporting of any vulnerable adult in harm, etc.`,
  [k.FOR_THE_PURPOSE_OF_ANY_LEGITIM]: `For the purpose of any legitimate interest pursued us on a case-by-case basis.`,
  [k.THE_ABOVE_PURPOSES_ALSO_APPLY]: `The above purposes also apply to employees of La Casa and Companion users.`,
  [k.PERSONAL_DATA_IS_COLLECTED_BY]: `Personal data is collected by us when:`,
  [k.INDIVIDUALS_PROVIDE_US_WITH_DA]: `Individuals provide us with data directly as part of any interaction with La Casa e.g.`,
  [k.WHEN_THEY_CONTACT_US_WITH_A_QU]: `when they contact us with a query or request.`,
  [k.WHEN_THEY_VISIT_OUR_OFFICES]: `when they visit our offices.`,
  [k.WHEN_THEY_WRITE_EMAIL_OR_PHON]: `when they write, email or phone us.`,
  [k.WHEN_THEY_CONTACT_US_VIA_THE_L]: `when they contact us via the La Casa Hub.`,
  [k.WHEN_THEY_CONTACT_US_VIA_THE_L1]: `when they contact us via the La Casa Companion app (or Companion app).`,
  [k.WHEN_THEY_SIGN_UP_AS_A_CLIENT]: `when they sign up as a client`,
  [k.WHEN_THEY_ENTER_INTO_A_CONTRAC]: `when they enter into a contractual relationship with La Casa`,
  [k.WHEN_THEY_ENGAGE_WITH_THE_ONB]: `when they engage with the ‘onboarding process’ (i.e. when La Casa’s assesses a users needs and what products and services apply to a client).`,
  [k.WE_ALSO_RECEIVE_PERSONAL_INFOR]: `We also receive personal information indirectly in the following scenarios:`,
  [k.VIA_A_REFERRER_WHO_WITH_CONSE]: `Via a referrer who, with consent, has asked us to make contact with an individual for the purpose of providing or exploring the potential to provide La Casa products and services to that individual.`,
  [k.VIA_A_REFERRER_WHO_WITH_CONSE1]: `Via a referrer who, with consent, has asked us to make contact with an individual for the purpose of entering into, or for taking steps to enter into, a contractual relationship with La Casa.`,
  [k.CAREHUB_CUSTOMER_CARE]: `CareHub / Customer Care.`,
  [k.RETAIL_MARKET_MONITORING_AND_R]: `Retail market monitoring and reporting activities.`,
  [k.WHERE_A_CLIENT_OR_EMPLOYEE_OF]: `Where a client or employee of La Casa provides contact details as an emergency contact or a referee.`,
  [k.THE_INFORMATION_WE_PROCESS]: `3. The information we process`,
  [k.IDENTITY_NAME_SURNAME_TITL]: `Identity – Name, surname, title, and other identifying information provided by you`,
  [k.DETAILS_PROVIDED_WHEN_YOU_CONT]: `Details provided when you contact us through our webform.`,
  [k.CONTACT_INFORMATION_ADDRESS]: `Contact information – Address, email address, phone number.`,
  [k.COMPANION_AND_SUBSCRIBERS_PE]: `Companion and Subscribers – Persons listed in the Managed Service Contract, including, if appliable,
    details of successors, assignees or those with power of attorney or other authorised or legal representatives.`,
  [k.DETAILS_OF_A_CLIENT_S_HEALTH_A]: `Details of a client’s health and habitation status – illnesses, medical conditions, health status,
    living status, cohabitation status, medications, emergency contacts, daily routines, etc. in order to provide monitoring and care.`,
  [k.SUPPLIER_DETAILS_CONTACT_INF]: `Supplier details – Contact information, bank details, VAT details and tax clearance information.`,
  [k.STAKEHOLDER_DETAILS_CONTACT]: `Stakeholder details – Contact information, role, meeting minutes / notes.`,
  [k.LICENCE_AUTHORISATION_APPLIC]: `Licence & Authorisation Applicant details – Contact information, Names of company directors, Engineers.`,
  [k.WEBSITE_USER_STATISTICS_GOOGL]: `Website user statistics (Google Analytics) – Location, time, date and details of your visit to our website
    (as anonymous statistics) Please see our Cookie Policy for more information.`,
  [k.OTHER_PERSONAL_INFORMATION_I]: `Other personal information – Information in relation to subject access requests (including proof of identity if required); Responses to surveys.`,
  [k.RECRUITMENT_AND_EMPLOYEE_DETAI]: `Recruitment and Employee details – Applicant CV’s, credentials, references etc, tax and bank details of successful applicants.`,
  [k.MARKETING_INFORMATION_IF_YOU]: `Marketing information – If you have expressed an interest in our Services or requested updates, we will contact
    you with relevant information until such time as you opt – out of communications.`,
  [k.WHY_WE_USE_YOUR_INFORMATION1]: `4. Why we use your information`,
  [k.IN_ADDITION_TO_THE_ABOVE_WE_O]: `In addition to the above, we only collect or process your personal data,
    where required, for one or more of the following purposes (or lawful basis for processing):`,
  [k.TO_ENTER_INTO_A_CONTRACT_OR_T]: `To enter into a contract, or to take steps to enter into a contract, or to fulfil the terms of a contract:`,
  [k.TO_PROVIDE_INFORMATION_TO_PROS]: `To provide information to prospective clients on our products and services.`,
  [k.TO_PROVIDE_OUR_PRODUCTS_AND_SE]: `To provide our products and services to a client on foot of a contract with La Casa.`,
  [k.TO_PROVIDE_FOLLOW_UP_SERVICES]: `To provide follow up services, aftercare, repairs, review of products and services, to a client on foot of a contract with La Casa.`,
  [k.TO_ENSURE_PAYMENT_TO_LA_CASA_O]: `To ensure payment to La Casa of any monies due.`,
  [k.ENGAGING_SERVICE_PROVIDERS_AND]: `Engaging service providers and suppliers and for the provision of and payment for goods and services.`,
  [k.FILLING_VACANT_POSITIONS_AND_F]: `Filling vacant positions and for the engagement and payment of employees.`,
  [k.TO_RUN_OUR_ORGANISATION_ON_A_D]: `To run our organisation on a day-to-day basis including to:`,
  [k.CONTACT_STAKEHOLDERS_BUSINESS]: `Contact stakeholders: Business contact information relating to stakeholders (e.g. business card information,
    contact details, role/position in company, etc) is processed for legitimate business-related purposes.`,
  [k.COMPILE_AND_PROCESS_YOUR_INFOR]: `Compile and process your information for audit, statistical, reporting or research purposes (including, in
    some instances, making your data anonymous) in order to help us understand market trends.`,
  [k.PROTECT_OUR_BUSINESS_REPUTATI]: `Protect our business, reputation, resources and equipment and manage our network systems, and information.`,
  [k.MANAGE_AND_ADMINISTER_OUR_LEGA]: `Manage and administer our legal and compliance affairs.`,
  [k.MANAGING_AND_RESPONDING_TO_COM]: `Managing and responding to complaints about us.`,
  [k.EFFECTIVELY_MANAGE_THE_FUNCTIO]: `Effectively manage the functionality and usability of the website.`,
  [k.WE_WILL_ONLY_PROCESS_YOUR_PERS]: `We will only process your personal data for the purposes for which they were originally collected, and it
    will only be processed further for the following closely related business purposes:`,
  [k.TRANSFERRING_THE_PERSONAL_DATA]: `transferring the personal data to an archive.`,
  [k.CONDUCTING_INTERNAL_AUDITS_OR]: `conducting internal audits or investigations.`,
  [k.IMPLEMENTING_BUSINESS_CONTROLS]: `implementing business controls.`,
  [k.CONDUCTING_STATISTICAL_ANALYSI]: `conducting statistical analysis or research as required.`,
  [k.PREPARING_FOR_OR_ENGAGING_IN_D]: `preparing for or engaging in dispute resolution.`,
  [k.USING_LEGAL_OR_BUSINESS_CONSUL]: `using legal or business consulting services; or`,
  [k.MANAGING_INSURANCE_ISSUES]: `managing insurance issues.`,
  [k.TO_PROTECT_THE_VITAL_INTERESTS1]: `To protect the vital interests of a person and or La Casa, such as:`,
  [k.IN_ORDER_TO_PROTECT_THE_HEALTH]: `In order to protect the health, safety and wellbeing of a client.`,
  [k.TO_CONTACT_EMERGENCY_SERVICES]: `To contact emergency services in case of an accident or emergency to seek aid for a person.`,
  [k.TO_REPORT_ANY_SUSPECTED_CRIMIN]: `To report any suspected criminal activity to the relevant authorities.`,
  [k.TO_INITIATE_CONTINUE_WITH_OR]: `To initiate, continue with or defence any legal claim or prospective legal claim.`,
  [k.TO_OBTAIN_LEGAL_ADVICE_ON_A_SP]: `To obtain legal advice on a specific matter.`,
  [k.TO_INVESTIGATE_MONITOR_OR_PRE]: `To investigate, monitor or prevent any unauthorised or illegal use of La Casa’s products or services.`,
  [k.COMPLYING_WITH_OUR_LEGAL_OBLIG]: `Complying with our legal obligations.`,
  [k.SPECIAL_CATEGORIES_OF_PERSONAL]: `Special categories of personal data.`,
  [k.WE_PROCESS_SPECIAL_CATEGORIES]: `We process special categories of personal data in accordance with Article 9 of the General Data Protection Regulation on the following basis:`,
  [k.EXPLICIT_CONSENT_OF_THE_DATA_S]: `Explicit consent of the data subject in order to provide contracted monitoring and or support products and services to the data subject.`,
  [k.EXPLICIT_CONSENT_OF_THE_DATA_S1]: `Explicit consent of the data subject to enter into or perform a contact of services with La Casa.`,
  [k.WHERE_PROCESSING_IS_NECESSARY]: `Where processing is necessary for the purposes of performing or exercising obligations or rights which are
    imposed or conferred by law on La Casa or the data subject in connection with employment, social security, or social protection.`,
  [k.FOR_THE_ESTABLISHMENT_EXERCIS]: `For the establishment, exercise, or defence of legal claims.`,
  [k.WHERE_PROCESSING_IS_NECESSARY1]: `Where processing is necessary to protect the vital interests of the data subject or of another natural person
    on a case-by-case basis, e.g. in the event of an emergency in order to contact emergency services.`,
  [k.WHO_WE_SHARE_YOUR_INFORMATI]: `5. Who we share your information with?`,
  [k.WE_ONLY_SHARE_YOUR_INFORMATION]: `We only share your information where it is necessary. We will not share your information with any third parties
    for the purposes of direct marketing. Sharing can occur in the following circumstances and with the following organisations.`,
  [k.A_CLIENT_S_COMPANION_A_CLIENT]: `A client’s Companion (a client’s nominated family and friends).`,
  [k.EMERGENCY_SERVICES_OR_MEDICAL]: `Emergency services or medical professionals (with the consent of the client).`,
  [k.MANDATED_BODIES_UNDER_STATUTE]: `Mandated bodies under statute, e.g. Police, etc.`,
  [k.DATA_PROCESSORS_WHO_ARE_THIRD]: `Data processors who are third parties who provide services for us. We have contracts in place with our data
    processors. This means that they cannot do anything with your personal information unless we have instructed
    them to do it. They are contractually bound to maintain an equivalent level of data protection and privacy
    as set out in this policy document and as required under GDPR.`,
  [k.WEBSITE_ANALYTICS_WHEN_YOU_VI]: `Website Analytics: When you visit lacasa.care, we use a third-party services, Google Analytics, to collect
    standard statistical information and details of visitor behaviour patterns. We do this to find out such things
    as the number of visitors to the various parts of the site. This information is only processed in a way that
    does not directly identify anyone. Please see our Cookie Policy for more information.`,
  [k.WE_WILL_KEEP_YOUR_PERSONAL_DAT]: `We will keep your personal data for as long as it is necessary to fulfil the purposes for which it was collected
    as described above and in accordance with our legal and regulatory obligations. The length of time we hold your
    personal data depends on a number of factors such as:`,
  [k.THE_TYPE_OF_DATA_WE_HOLD_ABOUT]: `The type of data we hold about you.`,
  [k.WHETHER_THERE_IS_A_LEGAL_OBLIG]: `Whether there is a legal obligation to hold the data for a minimum specified period.`,
  [k.WHERE_THERE_IS_A_SOUND_EVIDENC]: `Where there is a sound evidence-based reason to hold the data for a specified period.`,
  [k.LA_CASA_HAS_A_PERSONAL_DATA_RE]: `La Casa has a personal data retention period of eight years after a client ceases to be a client.`,
  [k.PROCESSING_YOUR_INFORMATION]: `6. Processing your information outside the EEA`,
  [k.WE_STORE_YOUR_DATA_ON_EU_BASED]: `We store your data on EU based servers that are based in other EU countries and are still protected by GDPR by
    application of the Data Protection Commission’s “One Stop Shop Mechanism” with the Irish Data Protection Commission
    as the lead National Supervisory Authority.`,
  [k.FOR_MORE_INFORMATION_ON_THE_DA]: `For more information on the Data Protection Commission’s “One Stop Shop Mechanism”, please visit the Data Protection
    Commission’s website at www.dataprotection.ie.`,
  [k.WHAT_ARE_MY_RIGHTS_WHEN_YOU]: `7. What are my rights when you process my data?`,
  [k.YOU_HAVE_A_NUMBER_OF_RIGHTS_IN]: `You have a number of rights in relation to your personal data. You may request access to your data, correction
    of any mistakes in our files, erasure of records where no longer required, restriction on the processing of your data,
    objection to the processing of your data or the basis for any international transfers.`,
  [k.LA_CASA_DOES_NOT_ENGAGE_IN_ANY]: `La Casa does not engage in any automated processing, automated decision making or profiling of data subjects or clients.`,
  [k.IN_ORDER_TO_REQUEST_YOUR_PERSO]: `In order to request your personal data, or to object or correct any error, please email your request to`,
  [k.INFO_LACASA_CARE]: `info@lacasa.care`,
  [k.YOU_ALSO_HAVE_THE_RIGHT_TO_COM]: `You also have the right to complain to the Data Protection Commission at`,
  [k.WWW_DATAPROTECTION_IE]: `www.dataprotection.ie`,
  [k.OR]: `or:`,
  [k.DATA_PROTECTION_COMMISSION]: `Data Protection Commission`,
  [k.FITZWILLIAM_SQUARE]: `21 Fitzwilliam Square`,
  [k.DUBLIN]: `Dublin 2`,
  [k.D_RD]: `D02 RD28`,
  [k.PLEASE_CONTACT_US_IN_THE_FIRST]: `Please contact us in the first instance so that we make rectify any issue.`,
  [k.CONTACT_US1]: `8. Contact Us`,
  [k.THE_PRIMARY_POINT_OF_CONTACT_F]: `The primary point of contact for all issues arising from this Policy, including requests to exercise data subject rights, 
    is our Data Protection Officer. You can get in touch by emailing us at`,
  [k.FOR_THE_ATTENTION_OF_THE_DATA]: `for the attention of the Data Protection Officer, La Casa.`,
  [k.THE_COMPANION_APP]: `10. The Companion app`,
  [k.THE_LA_CASA_COMPANION_APP_ALS]: `The La Casa Companion app, also referred to as the Companion app, is an app for a user’s phone or
    smart device that allows members of the circle of care to easily share information.`,
  [k.AS_PART_OF_ENTERING_INTO_ANY_A]: `As part of entering into any agreement with La Casa, a user may set the
    parameters of any monitoring or assistance required and consent to the processing of their personal data.
    The La Casa Companion app is a tool that allows App users provide care and support that the service user has requested.
    The app does not process any personal data beyond what was consented to by a user as outlined above with the
    specific exceptions listed below.`,
  [k.THE_LA_CASA_COMPANION_APP_NEED]: `The La Casa Companion app needs the following permissions on a user’s phone or smart device in order to function:`,
  [k.ALLOW_THE_LA_CASA_COMPANION_AP]: `Allow the La Casa Companion app to appear on a user’s phone screen or smart device screen.`,
  [k.ALLOW_THE_LA_CASA_COMPANION_AP1]: `Allow the La Casa Companion app use push notifications on a user’s phone or smart device.`,
  [k.ALLOW_THE_LA_CASA_COMPANION_AP2]: `Allow the La Casa Companion app access a device’s camera and take pictures and record video at the request of the user.`,
  [k.ALLOW_THE_LA_CASA_COMPANION_AP3]: `Allow the La Casa Companion app to access device’s microphone and record audio at the request of the user.`,
  [k.ALLOW_THE_LA_CASA_COMPANION_AP4]: `Allow the La Casa Companion app to access photos and media on a user’s device in order to store user’s pictures,
    photos, files and or share same with nominated Companion users, at the request of the user.`,
  [k.THE_REASON_THE_LA_CASA_COMPANI]: `The reason the La Casa Companion app requires the above access to a user’s phone or smart device is to allow
    the user to do the following activities via the La Casa Companion App:`,
  [k.ACCESS_AND_USE_THE_LA_CASA_COM]: `Access and use the La Casa Companion app on their phone or smart device.`,
  [k.NOTIFY_THE_USER_OF_ANY_ACTIVIT]: `Notify the user of any activities, notifications, events or communications on the La Casa Companion app or
    from the La Casa Hub or nominated family/friends/caregivers.`,
  [k.MAKE_AND_RECEIVE_AUDIO_VIDEO]: `Make and receive audio & video calls.`,
  [k.SEND_AND_RECEIVE_MEDIA_PHOTOS]: `Send and receive media (photos, video clips, etc.) to the Circle members.`,
  [k.UPON_INSTALLATION_OF_THE_LA_CA]: `Upon installation of the La Casa Companion app, or upon using the app for the first time, a user will be
    promoted to allow or reject access to the above permissions by their phone or smart device. The La Casa
    Companion app cannot function without the above access. As such, access is necessary to provide ongoing care
    and support to a user via a user’s phone or smart device.`,
  [k.COOKIES_USED_ON_THE_LA_CASA_CO]: `Cookies used on the La Casa Companion App`,
  [k.THE_LA_CASA_COMPANION_APP_DOES]: `The La Casa Companion App does not use cookies, however, the App may allow users to access the internet,
    but La Casa is not responsible for the content or cookies used on any non-La Casa website.`,
  [k.LAST_REVIEW_NOVEMBER]: `Last review: November 2022`,
  [k.THIS_POLICY_MAY_BE_UPDATED_WIT]: `This Policy may be updated without further notice and data subjects, client and service users are encouraged
    to review all La Casa’s privacy policies regularly.`,
  [k.THIS_IS_LA_CASA_S_PRIVACY_POLI]: `This is La Casa’s Privacy Policy to be read in conjunction with our Cookie Policy.`,
  [k.LA_CASA_IS_COMMITTED_TO_PROTEC]: `La Casa is committed to protecting and respecting your privacy. This policy (together with our terms of use)
    and any other documents referred to on it, sets out the basis on which any personal data we collect from you,
    or that you provide to us, will be processed. Please read the following carefully to understand our views and
    practices regarding your personal data and how we will treat your data.`,
  [k.KEEP_ON_TRACK_OF_MEDICATION_W]: `Keep on track of medication, with optional reminders, for members of the care circle or the service user.
    Notifications may also be set for Presciption re-ordering.`,
  [k.TO_GET_STARTED_SIMPLY_ENTER_T]: `To get started, simply enter the details of any medication and then set a reminder using the clock function.`,
  [k.MEDICATION]: `Medication`,
  [k.EXCHANGING_SECURE_MESSAGES_WIT]: `Exchanging secure messages within Companion is a key feature of the app.`,
  [k.THE_MESSAGING_FUNCTION_KEEPS_A]: `The messaging function keeps a permanent record of messages and interactions for future reference. You may use a general chat "room"
    available to all members of the circle or create additional private groups to discuss private or sensitive matters.`,
  [k.MESSAGING]: `Messaging`,
  [k.REGISTRATION_COMPLETE]: `Registration complete`,
  [k.THANK_YOU_FOR_JOINING_LA_CASA]: `Thank you for joining La Casa, the collaboration platform for carers, families and seniors.`,
  [k.SOME_USEFUL_LINKS]: `Some useful links:`,
  [k.SERVICE_HOMEPAGE]: `Service homepage`,
  [k.BRIEF_INTRODUCTION_TO_THE_PLAT]: `Brief introduction to the platform`,
  [k.PRODUCT_BLOG]: `Product blog`,
  [k.YOU_MAY_VISIT]: `You may visit`,
  [k.OUR_TWITTER]: `our Twitter`,
  [k.FOR_UPDATES_OR_EMAIL]: `for updates or email`,
  [k.START_USING_THE_APPLICATION]: `Start using the application`,
  [k.THERE_IS_NO_HARDWARE_ATTACHED]: `There is no hardware attached. To utilize activity, please connect the hardware hub and attach one or more sensors in the menu on the left.`,
  [k.OUT_OF_HOME]: `Out of home:`,
  [k.RARELY_LESS_THAN]: `rarely (less than 10%)`,
  [k.LAST_DOOR_EVENT]: `Last door event:`,
  [k.HOURS_AGO]: `2 hours ago`,
  [k.APPLIANCE_USAGE]: `Appliance usage:`,
  [k.AS_USUAL]: `as usual`,
  [k.MINUTES_AGO]: `10 minutes ago`,
  [k.RESTING_TIME]: `Resting time:`,
  [k.LONG_OVER_HOURS_DAILY]: `long (over 5 hours daily)`,
  [k.WATCHING_TV]: `Watching TV:`,
  [k.FREQUENTLY_OVER]: `frequently (over 30%)`,
  [k.VISITS]: `Visits:`,
  [k.MANY_MORE_THAN_WEEKLY]: `many (more than 5 weekly)`,
  [k.LAUNDRY]: `Laundry:`,
  [k.RARELY_LESS_THAN_MONTHLY]: `rarely (less than 2 monthly)`,
  [k.BATHROOM_VISITS]: `Bathroom visits:`,
  [k.NORMAL_BELOW_VISITS]: `normal (below 5 visits)`,
  [k.NO_NEW_INSIGHTS]: `No new insights.`,
  [k.NO_APPOINTMENTS]: `No appointments.`,
  [k.THERE_ARE]: `There are`,
  [k.UPCOMING_APPOINTMENTS]: `upcoming appointments.`,
  [k.HEADER]: `-header`,
  [k.CONTENTS1]: `-contents`,
  [k.YOU_HAVE]: `You have`,
  [k.CONTACTS]: `contacts,`,
  [k.OF_THEM_AWAITING_ACCEPTANCE]: `of them awaiting acceptance.`,
  [k.OVERALL_REPORT]: `Overall report`,
  [k.NO_DEVICES_MATCHING_YOUR_CRITE]: `No devices matching your criteria, try tuning some knobs.`,
  [k.DASHBOARD_DEVICE]: `dashboard-device-`,
  [k.OVERALL]: `overall`,
  [k.WARNING]: `warning`,
  [k.NO_DOCUMENTS]: `No documents.`,
  [k.NO_NEW_DOCUMENTS]: `No new documents,`,
  [k.DOCUMENTS_TOTAL]: `documents total.`,
  [k.NEW_DOCUMENTS]: `new documents.`,
  [k.NO_MEDICATION]: `No medication.`,
  [k.ACTIVE_MEDICATIONS]: `active medications.`,
  [k.THERE_IS_NO_HARDWARE_ATTACHED1]: `There is no hardware attached. To utilize metrics, please connect the hardware hub and attach one or more sensors in the menu on the left.`,
  [k.ROOM_TEMPERATURE]: `Room temperature:`,
  [k.C]: `20 °C`,
  [k.ROOM_HUMIDITY]: `Room humidity:`,
  [k._7]: `63 %`,
  [k.ENERGY_USAGE]: `Energy usage:`,
  [k.KWH_TOTAL]: `451 kWh total`,
  [k.BLOOD_PRESSURE]: `Blood Pressure:`,
  [k._8]: `90/142`,
  [k.BMI]: `BMI:`,
  [k.N_A]: `N/A`,
  [k.OXYGEN_SATURATION]: `Oxygen saturation:`,
  [k._9]: `98 %`,
  [k.SLEEPING]: `Sleeping:`,
  [k.H]: `6.2 h`,
  [k.SLEEPING_QUALITY]: `Sleeping quality:`,
  [k.GOOD]: `good`,
  [k.BLOOD_GLUCOSE]: `Blood glucose:`,
  [k.CHOLESTEROL_LEVEL]: `Cholesterol level:`,
  [k.HEART_ARYTHMIA]: `Heart arrythmia:`,
  [k.PAIN]: `Pain:`,
  [k.NONE]: `none`,
  [k.THERE_ARE_NO_DEVICES_YET]: `There are no devices yet.`,
  [k.THE_FEATURE_IS_UNAVAILABLE_CO]: `The feature is unavailable. Contact La Casa for further assistance.`,
  [k.IMPROVE_SOCIAL_CONTACTS]: `Improve social contacts`,
  [k.RECOMMENDATIONS]: `Recommendations`,
  [k.INVITE_NEW_CONTACT]: `Invite new contact`,
  [k.CONNECT_THE_HUB]: `Connect the hub`,
  [k.ADD_SENSOR]: `Add sensor`,
  [k.VITALS1]: `VITALS`,
  [k.NEMINI_LICET_HIC]: `Nemini licet hic.`,
  [k.HIC_SUNT_LEONES]: `Hic sunt leones.`,
  [k.LICENSE]: `License`,
  [k.THERE_IS_A_PREMIUM_OPTION_OF_T]: `There is a premium option of the system for advanced use. This is the recommended option for professionals and care organizations, for more details please contact`,
  [k.LA_CASA]: `La Casa`,
  [k.LICENSE_TYPE]: `License type:`,
  [k.ORGANIZATION]: `Organization`,
  [k.THE_USER_IS_REGISTERED_UNDER_T]: `The user is registered under the organization listed below. The system allows certain customization to be made on the organization level.`,
  [k.ORGANIZATION1]: `Organization:`
};

export default values;

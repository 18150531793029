export enum ContactRole {
  ENDUSER = 'end-user',
  FORMAL_CARE = 'formal-care',
  INFORMAL_CARE = 'informal-care',
  GP = 'gp'
}

export enum ContactStatus {
  PENDING_INVITATION = 'pending-invitation',
  PENDING_REGISTRATION = 'pending-registration',
  ACTIVE = 'active'
}

export interface Contact {
  id: string
  sub: string,
  createdAt: string | Date
  status: ContactStatus

  name: string
  email: string
  phoneNumber: string
  picture: string

  role: ContactRole
}



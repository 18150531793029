import { createContext, useContext, useEffect, useState } from 'react';
import Axios from "axios";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

import { Loadable, Notification } from '../types'
import { AuthContext } from './AuthContext'
import { DataContext } from './DataContext'
import { DAY } from "../constants/TimeConstants";

interface NotificationContextType {
  interval: number,
  setInterval: (interval: number) => void,
  notifications: Loadable<Notification>,
  reloadNotifications: () => Promise<any>
  resolveNotification: (notificationId: string, action: string, data?: any) => Promise<any>,
  resolveNotifications: (domain: string, ids: Array<string>, action: string) => Promise<any>,
  showNotifications: boolean,
  setShowNotifications: (show: boolean) => void
}

const initialContext: NotificationContextType = {
  interval: DAY,
  setInterval: () => {
  },
  notifications: {loading: true},
  reloadNotifications: () => Promise.resolve(),
  resolveNotification: () => Promise.resolve(),
  resolveNotifications: () => Promise.resolve(),
  showNotifications: false,
  setShowNotifications: () => {
  }
}

const NotificationContext = createContext<NotificationContextType>(initialContext);
const SERVICE_URL = process.env.REACT_APP_SERVICE_URL || "http://localhost:8080";
const NOTIFICATION_REFRESH_TIMEOUT = parseInt(process.env.REACT_APP_NOTIFICATION_REFRESH_TIMEOUT || "300000");
const VAPID_KEY = "BI6sklTGlfyvuyuGtVKBW6SmlE4epK4WTOryyzS-YKe1WOGfMIXasqQ_Y_PMSTF3uYGZcTfUbPqSQ2TncO0zs7A"
const firebaseConfig = {
  apiKey: "AIzaSyD9cl23Vj5_Ojm7Cfp3-ncnq8aTxfDdpW4",
  authDomain: "la-casa-circle-of-care-app.firebaseapp.com",
  projectId: "la-casa-circle-of-care-app",
  storageBucket: "la-casa-circle-of-care-app.appspot.com",
  messagingSenderId: "1056670196056",
  appId: "1:1056670196056:web:686fa50880d7b902b5c52d"
};

function NotificationProvider({children}: any) {

  const {auth} = useContext(AuthContext)
  const {reloadDevices} = useContext(DataContext)
  const [timer, setTimer] = useState(0)
  const [notifications, setNotifications] = useState<Loadable<Notification>>({loading: true})
  const [showNotifications, setShowNotifications] = useState(false);
  const [interval, setInterval] = useState(DAY)

  useEffect(() => {
    try {
      const messaging = getMessaging(initializeApp(firebaseConfig))
      getToken(messaging, {vapidKey: VAPID_KEY})
          .then(token => registerToken(token))
          .catch(err => console.log("Error registering push messaging token", err))
      onMessage(messaging, (() => reloadNotifications()))
    } catch (err) {
      console.log("Error initializing messaging", err)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => setTimer(timer + 1), NOTIFICATION_REFRESH_TIMEOUT)
    reloadNotifications()
        .catch(err => console.log("Error reloading notifications", err))
  }, [auth, timer]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reloadNotifications()
        .catch(err => console.log("Error reloading notifications", err))
  }, [interval]) // eslint-disable-line react-hooks/exhaustive-deps

  const registerToken = (token: string) => Axios.post(`${SERVICE_URL}/api/tokens`, {token, type: "firebase"})

  const reloadNotifications = () => {
    if (auth) {
      setNotifications(() => ({loading: true}))
      return Axios.get(`${SERVICE_URL}/api/notifications`, {params: {interval}})
          .then(response => setNotifications({loading: false, data: response.data}))
          .catch(err => {
            if (err.response.status === 403) window.location.href = `${process.env.REACT_APP_BASE_HREF}`;
            else setNotifications({loading: false, error: err})
          });
    }
    return Promise.resolve()
  }

  const resolveNotification = (notificationId: string, action: string, data?: any) => {
    return Axios.post(`${SERVICE_URL}/api/notifications/${notificationId}/${action}`, data).then(response => {
      setNotifications({...notifications, data: notifications.data?.filter(n => n.id !== notificationId)})
      if (response.data.reloadDevices) reloadDevices()
          .catch(err => console.log("Error reloading devices", err))
    })
  }

  const resolveNotifications = (domain: string, ids: Array<string>, action: string) => {
    return Axios.post(`${SERVICE_URL}/api/notifications/${action}`, ids.map(id => `${domain}-${id}`)).then(response => {
      setNotifications({...notifications, data: notifications.data?.filter(n => !ids.includes(n.id))})
      if (response.data.reloadDevices) reloadDevices()
          .catch(err => console.log("Error reloading devices", err))
    })
  }

  return (
      <NotificationContext.Provider value={{
        interval, setInterval,
        notifications,
        reloadNotifications,
        resolveNotification,
        resolveNotifications,
        showNotifications,
        setShowNotifications
      }}>
        {children}
      </NotificationContext.Provider>
  );
}

export { NotificationContext, NotificationProvider };

import { createContext, useContext, useEffect, useState } from 'react';
import Axios from "axios";

import { Alert, Loadable } from '../types'
import { AuthContext } from './AuthContext'
import { DAY } from "../constants/TimeConstants";

interface AlertContextType {
  interval: number,
  setInterval: (interval: number) => void,
  alerts: Loadable<Alert>,
  reloadAlerts: () => Promise<any>
  resolveAlert: (id: string, action: string) => Promise<any>,
  resolveAlerts: (ids: Array<string>, action: string) => Promise<any>
}

const initialContext: AlertContextType = {
  interval: DAY,
  setInterval: () => {
  },
  alerts: {loading: true},
  reloadAlerts: () => Promise.resolve(),
  resolveAlert: () => Promise.resolve(),
  resolveAlerts: () => Promise.resolve()
}

const AlertContext = createContext<AlertContextType>(initialContext);
const SERVICE_URL = process.env.REACT_APP_SERVICE_URL || "http://localhost:8080";
const ALERT_REFRESH_TIMEOUT = parseInt(process.env.REACT_APP_ALERT_REFRESH_TIMEOUT || "300000");

function AlertProvider({children}: any) {

  const {auth} = useContext(AuthContext)
  const [timer, setTimer] = useState(0)
  const [alerts, setAlerts] = useState<Loadable<Alert>>({loading: true})
  const [interval, setInterval] = useState(DAY)

  useEffect(() => {
    setTimeout(() => setTimer(timer + 1), ALERT_REFRESH_TIMEOUT)
    reloadAlerts()
  }, [auth, timer]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reloadAlerts()
  }, [interval]) // eslint-disable-line react-hooks/exhaustive-deps

  const reloadAlerts = () => {
    if (!auth) return Promise.resolve()
    setAlerts(() => ({loading: true}))
    return Axios.get(`${SERVICE_URL}/api/alerts`, {params: {interval}})
        .then(response => setAlerts({loading: false, data: response.data}))
        .catch(err => setAlerts({loading: false, error: err}));
  }

  const resolveAlert = (id: string, action: string) => {
    return Axios.post(`${SERVICE_URL}/api/alerts/${id}/${action}`).then(() => {
      setAlerts({...alerts, data: alerts.data?.filter(n => n.id !== id)})
    })
  }

  const resolveAlerts = (ids: Array<string>, action: string) => {
    return Axios.post(`${SERVICE_URL}/api/alerts/${action}`, ids).then(() => {
      setAlerts({...alerts, data: alerts.data?.filter(n => !ids.includes(n.id))})
    })
  }

  return (
      <AlertContext.Provider value={{
        interval, setInterval,
        alerts,
        reloadAlerts,
        resolveAlert,
        resolveAlerts
      }}>
        {children}
      </AlertContext.Provider>
  );
}

export { AlertContext, AlertProvider };

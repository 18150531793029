import styled from 'styled-components';
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";

const FullHeight = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  min-height: 100vh;
`;

export function Contents({children}: any) {

  const {config} = useContext(DataContext);

  const bgUrl = `linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.2)), url(/backgrounds/${config?.data?.bg}.jpg)`;

  return <FullHeight className="d-flex flex-fill flex-grow-1 h-100"
                     style={{backgroundImage: bgUrl, backgroundSize: 'cover'}}>{children}</FullHeight>;
}
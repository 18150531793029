import { useContext, useState } from 'react'
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from 'reactstrap';
import { faAngleDown, faAngleRight, faStream } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components';

import { NotificationContext } from "../../contexts/NotificationContext";
import { Loading } from "../Loading";
import { LoadingError } from "../LoadingError";
import { Notification } from "../../types";
import { DAY, MONTH, QUARTER, WEEK, YEAR } from "../../constants/TimeConstants";
import {
  BMIScale,
  Checkers,
  DoubleLinearScale,
  OptionsScale,
  SingleEnumScale,
  SingleLinearScale,
  SmileysScale
} from "./Editors";
import {
  AlertsOption,
  DeviceChangeOption,
  UserMessagesOption,
  UserVerificationOption,
  VitalsRequestsOption
} from "./Options";
import { SidePane } from "../SidePane";

const NoNotificationsIcon = styled(FontAwesomeIcon)`
  margin-top: 50px;
  margin-bottom: 20px;
`
const ThinDropdownCaret = styled(DropdownToggle)`
  padding-left: 3px !important;
  padding-right: 0 !important;
`

const EVERYTHING: number = YEAR * 5

const INFO: Record<string, Record<string, any>> = {
  // SingleLinearScale
  "vitals-bmi-request": [
    {label: 'Height', unit: 'cm', min: 120, max: 230, value: 170},
    {label: 'Weight', unit: 'kg', min: 40, max: 120, value: 70}
  ],
  "vitals-oxygen-saturation-request": {label: 'Value', unit: '%', min: 80, max: 100, value: 95},
  "vitals-sleeping-request": {label: 'Value', unit: 'hours', min: 1, max: 16, value: 8},
  "vitals-blood-glucose-request": {label: 'Value', unit: 'mg/dL', min: 80, max: 250, value: 120},
  "vitals-cholesterol-level-request": {label: 'Value', unit: 'mg/dL', min: 100, max: 300, value: 200},
  // DoubleLinearScale
  "vitals-blood-pressure-request": [
    {label: 'Systolic', unit: 'mmHg', min: 30, max: 200, value: 120},
    {label: 'Diastolic', unit: 'mmHg', min: 30, max: 120, value: 90}
  ],
  // EnumScale
  "vitals-sleeping-quality-request": {min: 0, max: 4, value: 3, steps: ['Very bad sleep', 'Bad sleep', 'Moderate sleep', 'Good sleep', 'Very good sleep']},
  "vitals-pain-request": {min: 0, max: 4, value: 1, steps: ['No pain', 'Somewhat pain', 'Moderate pain', 'Intense pain', 'Very intense pain']},
  "vitals-mood-request": {min: 0, max: 4, value: 1, steps: ['Feeling bad', 'Not great', 'So-so', 'Good', 'Excellent']},
  // EnumCheckers
  "vitals-heart-arythmia-request": [
    {label: 'Fluttering in the chest', value: 'fluttering'},
    {label: 'Racing heartbeat (tachycardia)', value: 'racing'},
    {label: 'Slow heartbeat (bradycardia)', value: 'slow'},
    {label: 'Chest pain', value: 'pain'},
    {label: 'Shortness of breath', value: 'shortness'},
    {label: 'Anxiety', value: 'anxiety'},
    {label: 'Fatigue', value: 'fatigue'},
    {label: 'Lightheadedness or dizziness', value: 'lightheadedness'},
    {label: 'Sweating', value: 'sweating'},
    {label: 'Fainting (syncope) or near fainting', value: 'fainting'}
  ],
  // OptionsScale
  "question-adl-message": {value: 1},
  "question-iadl-message": {value: 1}
}

export function NotificationPanel() {

  const {interval, setInterval, notifications, reloadNotifications, resolveNotification, showNotifications, setShowNotifications} = useContext(NotificationContext)
  const [openNotifications, setOpenNotifications] = useState<Record<string, boolean>>({})
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [hideNotifications, setHideNotifications] = useState<Record<string, boolean>>({})
  const [timeChooserOpen, setTimeChooserOpen] = useState(false)
  const [data, setData] = useState<Record<string, Record<string, any>>>({})

  const hasNotifications = (domain: string) => notifications.data?.find(n => n.id.startsWith(domain))
  const needsAcceptDeclineButtons = (notification: any) => notification.type === 'device-transfer-acknowledge'
  const needsDismissButton = (notification: any) => ['device-transfer-refuse-acknowledge', 'alert-message', 'invitationCompleted-message'].includes(notification.type)
  const needsSingleLinearScale = (notification: any) => ['vitals-oxygen-saturation-request',
    'vitals-sleeping-request', 'vitals-blood-glucose-request', 'vitals-cholesterol-level-request'].includes(notification.type)
  const needsSingleEnumScale = (notification: any) => ['vitals-sleeping-quality-request', 'vitals-pain-request'].includes(notification.type)
  const needsDoubleLinearScale = (notification: any) => ['vitals-blood-pressure-request'].includes(notification.type)
  const needsBMIScale = (notification: any) => ['vitals-bmi-request'].includes(notification.type)
  const needsSmileys = (notification: any) => ['vitals-mood-request'].includes(notification.type)
  const needsCheckers = (notification: any) => ['vitals-heart-arythmia-request'].includes(notification.type)
  const needsConfirmDismiss = (notification: any) => ['meds-request'].includes(notification.type)
  const needsYesNoButtons = (notification: any) => ['question-gds-message'].includes(notification.type)
  const needsOptionsScale = (notification: any) => ['question-adl-message', 'question-iadl-message'].includes(notification.type)

  const trimLines = (lines: string) => lines.split('\n').map(line => line.trim()).join('\n')
  const toColor = (severity: number) => severity === 1 ? 'danger' : severity === 2 ? 'warning' : 'info'
  const bySeverityAndDateDesc = (first: Notification, second: Notification) => first.severity !== second.severity ?
      first.severity - second.severity : (first.timestamp && second.timestamp) ? Date.parse(second.timestamp) - Date.parse(first.timestamp) : 0
  const toggleNotification = (notificationId: string) => setOpenNotifications({...openNotifications, [notificationId]: !openNotifications[notificationId]})
  const byHideOptions = (v: Notification) => {
    if (v.id.startsWith('appointment')) return !hideNotifications.appointments
    if (v.id.startsWith('vitals')) return !hideNotifications.vitals
    return (v.id.startsWith('alert')) ? !hideNotifications.alerts && !hideNotifications[`alerts.${v.code}`] : !hideNotifications[v.code]
  }

  const toIntervalName = (value: number | undefined) => {
    switch (value) {
      case WEEK:
        return "week"
      case MONTH:
        return "month"
      case QUARTER:
        return "quarter"
      case YEAR:
        return "year"
      case EVERYTHING:
        return "all data"
      default:
        return "24 hours"
    }
  }

  const Notification = ({notification}: any) => (
      <Col md="12"
           className={`text-primary position-relative mt-2 ${openNotifications[notification.id] ? "mb-4" : ""}`}>
        <Label className="cursor-pointer mb-0" onClick={() => toggleNotification(notification.id)}>
          <div className="d-flex flex-row">
            <FontAwesomeIcon size="lg" icon={openNotifications[notification.id] ? faAngleDown : faAngleRight}
                             className={`text-${toColor(notification.severity)}`} style={{marginTop: 3, width: 12}}/>
            <div className="flex-fill">
              <span className="ms-3">{notification.title}</span>
              <div className="text-dark small ms-3 cursor-pointer"
                   onClick={() => toggleNotification(notification.id)}>{notification.subtitle || ""}</div>
            </div>
          </div>
        </Label>
        {openNotifications[notification.id] ? <NotificationBody notification={notification}/> : null}
      </Col>
  )

  const NotificationBody = ({notification}: any) => (
      <NotificationText notification={notification}/>
  )

  const AcceptDeclineButtons = ({notification}: any) => (
      <>
        <Button size="sm" color="primary" className="ms-3 float-end"
                onClick={() => resolveNotification(notification.id, 'accept')}>Accept handover</Button>
        <Button outline size="sm" color="warning" className="float-end"
                onClick={() => resolveNotification(notification.id, 'decline')}>Refuse handover</Button>
      </>
  )

  const YesNoButtons = ({notification}: any) => (
      <div className="btn-group w-50 ms-4">
        <Button outline color="primary" className="w-50"
                onClick={() => resolveNotification(notification.id, 'submit', {value: 0})}>{notification.options[0]}</Button>
        <Button outline color="primary" className="w-50"
                onClick={() => resolveNotification(notification.id, 'submit', {value: 1})}>{notification.options[1]}</Button>
      </div>
  )

  const ConfirmRefuseButtons = ({notification}: any) => (
      <>
        <Button size="sm" color="primary" className="ms-3 float-end"
                onClick={() => resolveNotification(notification.id, 'confirm')}>Confirm taking meds</Button>
        <Button outline size="sm" color="warning" className="float-end"
                onClick={() => resolveNotification(notification.id, 'refuse')}>Dismiss</Button>
      </>
  )

  const DismissButton = ({notification}: any) => (
      <Button outline size="sm" color="primary" className="ms-3 float-end"
              onClick={() => resolveNotification(notification.id, 'dismiss')}>Dismiss</Button>
  )

  const NotificationText = ({notification}: any) => (
      <>
        <div className="mb-3 small text-dark" style={{marginLeft: "1.6rem"}}>{trimLines(notification.text)}</div>
        {
          needsSingleLinearScale(notification) ?
              <SingleLinearScale notification={notification} info={INFO[notification.type]} data={data}
                                 setData={setData}/> :
              needsSingleEnumScale(notification) ?
                  <SingleEnumScale notification={notification} info={INFO[notification.type]} data={data}
                                   setData={setData}/> :
                  needsDoubleLinearScale(notification) ?
                      <DoubleLinearScale notification={notification} info={INFO[notification.type]} data={data}
                                         setData={setData}/> :
                      needsBMIScale(notification) ?
                          <BMIScale notification={notification} info={INFO[notification.type]} data={data}
                                    setData={setData}/> :
                          needsSmileys(notification) ?
                              <SmileysScale notification={notification} info={INFO[notification.type]} data={data}
                                            setData={setData}/> :
                              needsCheckers(notification) ?
                                  <Checkers notification={notification} info={INFO[notification.type]} data={data}
                                            setData={setData}/> :
                                  needsAcceptDeclineButtons(notification) ?
                                      <AcceptDeclineButtons notification={notification}/> :
                                      needsYesNoButtons(notification) ? <YesNoButtons notification={notification}/> :
                                          needsOptionsScale(notification) ?
                                              <OptionsScale notification={notification} info={INFO[notification.type]}
                                                            data={data} setData={setData}/> :
                                              needsConfirmDismiss(notification) ?
                                                  <ConfirmRefuseButtons notification={notification}/> :
                                                  needsDismissButton(notification) ?
                                                      <DismissButton notification={notification}/> : null
        }
      </>
  )

  const NoNotifications = () => (
      <div className="text-dark text-center">
        <NoNotificationsIcon icon={faBell} className="text-info" size="4x"/>
        <h4 className="fw-light">Nothing new, yet.</h4>
        <div className="px-3 mt-3">Notifications will show up here as soon as something important happens.
          For impatient: <u className="text-info text-underline cursor-pointer" onClick={reloadNotifications}>refresh
            now</u>.
        </div>
      </div>
  )

  const FilterIcon = () => (
      <FontAwesomeIcon icon={faStream} size="lg"
                       className={`text-${showOptions ? "info" : "dark"} ms-3 cursor-pointer hover-error`}
                       onClick={() => setShowOptions(!showOptions)}/>
  )

  const Options = () => (
      <div className="mt-3 small mb-3">
        <div>
          <span className="me-2">Show notifications:</span>
          <Dropdown id="device-metrics-time-chooser" size="sm" isOpen={timeChooserOpen} className="d-inline"
                    toggle={() => setTimeChooserOpen(!timeChooserOpen)}>
            <span onClick={() => setTimeChooserOpen(!timeChooserOpen)}
                  className="mt-1 hover-text-info cursor-pointer fw-normal">{toIntervalName(interval)}</span>
            <ThinDropdownCaret caret color="white"/>
            <DropdownMenu end>
              <DropdownItem className="small" onClick={() => setInterval(DAY)}>24 hours</DropdownItem>
              <DropdownItem className="small" onClick={() => setInterval(WEEK)}>Week</DropdownItem>
              <DropdownItem className="small" onClick={() => setInterval(MONTH)}>Month</DropdownItem>
              <DropdownItem className="small" onClick={() => setInterval(QUARTER)}>Quarter</DropdownItem>
              <DropdownItem className="small" onClick={() => setInterval(YEAR)}>Year</DropdownItem>
              <DropdownItem className="small" divider/>
              <DropdownItem className="small" onClick={() => setInterval(EVERYTHING)}>Everything</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        {hasNotifications('verify') ? <UserVerificationOption hideNotifications={hideNotifications}
                                                              setHideNotifications={setHideNotifications}/> : null}
        {hasNotifications('change') ? <DeviceChangeOption hideNotifications={hideNotifications}
                                                          setHideNotifications={setHideNotifications}/> : null}
        {hasNotifications('user') ? <UserMessagesOption hideNotifications={hideNotifications}
                                                        setHideNotifications={setHideNotifications}/> : null}
        {hasNotifications('alert') ? <AlertsOption notifications={notifications} hideNotifications={hideNotifications}
                                                   setHideNotifications={setHideNotifications}/> : null}
        {hasNotifications('vitals') ? <VitalsRequestsOption hideNotifications={hideNotifications}
                                                            setHideNotifications={setHideNotifications}/> : null}
      </div>
  )

  return (
      <SidePane isOpen={showNotifications} onRequestClose={() => setShowNotifications(false)}>
        <div className="p-4">
          <Row>
            <Col md="12">
              <div className="float-end mt-1 d-flex align-items-center justify-content-center">
                <FilterIcon/>
              </div>
              <h3 className="text-dark fw-light">Notifications</h3>
              <div className="text-dark small">Shows relevant messages and announcements.</div>
            </Col>
          </Row>
          <Row>
            {showOptions ? <Options/> : null}
          </Row>
          <Row className="mt-2">
            {notifications.loading && <Loading/>}
            {notifications.error && <LoadingError error={notifications.error}/>}
            {notifications.data?.length === 0 && <NoNotifications/>}
            {notifications.data?.filter(byHideOptions)
                .sort(bySeverityAndDateDesc)
                .map(n => <Notification key={n.id} notification={n}/>)}
          </Row>
        </div>
      </SidePane>
  )

}


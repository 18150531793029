export enum AccessRole {
  OWNER = 'owner',
  EDITOR = 'editor',
  VIEWER = 'viewer'
}

export enum UserRole {
  USER = 'user',
  ORG_ADMIN = 'org-admin',
  ADMIN = 'admin'
}
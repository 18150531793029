import { Capacitor } from "@capacitor/core";

// import { CallNumber } from 'capacitor-call-number';

export function isMobile() {
  return Capacitor.isNativePlatform() || window.outerWidth < 500;
}

export function isIOS() {
  return Capacitor.getPlatform() === 'ios';
}

export function canCall() {
  return Capacitor.isPluginAvailable("CallNumber");
}

export function call(number: string) {
  console.log('calling', number);
  // return CallNumber.call({ number })
}
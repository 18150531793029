import { createContext, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { DataContext } from "./DataContext";

interface Theme {
  code: string
  name: string
  description: string
  default?: boolean
}

interface ThemeContextType {
  themes: Array<Theme>
  selectedTheme: string
  setSelectedTheme: (theme: string) => void
}

const DEFAULT_THEME = 'lacasa'
const THEMES: Array<Theme> = [
  {code: 'lacasa', name: 'La Casa', description: 'Default theme', default: true},
  {code: 'cerulean', name: 'Cerulean', description: 'A calm blue sky'},
  {code: 'cosmo', name: 'Cosmo', description: 'An ode to Metro'},
  {code: 'cyborg', name: 'Cyborg', description: 'Jet black and electric blue'},
  {code: 'darkly', name: 'Darkly', description: 'Flatly in night mode'},
  {code: 'flatly', name: 'Flatly', description: 'Flat and modern'},
  {code: 'journal', name: 'Journal', description: 'Crisp like a new sheet of paper'},
  {code: 'litera', name: 'Litera', description: 'The medium is the message'},
  {code: 'lumen', name: 'Lumen', description: 'Light and shadow'},
  {code: 'lux', name: 'Lux', description: 'A touch of class'},
  {code: 'materia', name: 'Materia', description: 'Material is the metaphor'},
  {code: 'minty', name: 'Minty', description: 'A fresh feel'},
  {code: 'morph', name: 'Morph', description: 'A neumorphic layer'},
  {code: 'pulse', name: 'Pulse', description: 'A trace of purple'},
  {code: 'quartz', name: 'Quartz', description: 'A glassmorphic layer'},
  {code: 'sandstone', name: 'Sandstone', description: 'A touch of warmth'},
  {code: 'simplex', name: 'Simplex', description: 'Mini and minimalist'},
  {code: 'sketchy', name: 'Sketchy', description: 'A hand-drawn look for mockups and mirth'},
  {code: 'slate', name: 'Slate', description: 'Shades of gunmetal gray'},
  {code: 'solar', name: 'Solar', description: 'A spin on Solarized'},
  {code: 'spacelab', name: 'Spacelab', description: 'Silvery and sleek'},
  {code: 'superhero', name: 'Superhero', description: 'The brave and the blue'},
  {code: 'united', name: 'United', description: 'Ubuntu orange and unique font'},
  {code: 'yeti', name: 'Yeti', description: 'A friendly foundation'},
  {code: 'zephyr', name: 'Zephyr', description: 'Breezy and beautiful'}
]
const initialContext: ThemeContextType = {
  themes: THEMES,
  selectedTheme: '',
  setSelectedTheme: () => {
  }
}

const ThemeContext = createContext<ThemeContextType>(initialContext);

function ThemeProvider({defaultTheme, children}: any) {

  const {config, updateAppearance} = useContext(DataContext)
  const [selectedTheme, setSelectedTheme] = useState(defaultTheme);

  useEffect(() => setSelectedTheme(config?.data?.theme), [config])

  const setTheme = (theme: string) => updateAppearance({theme}).then(() => setSelectedTheme(theme))

  const HelmetLink = () => <Helmet>
    <link rel="stylesheet" type="text/css" href={`/themes/${selectedTheme}/bootstrap.min.css`}/>
  </Helmet>

  return (
      <ThemeContext.Provider value={{themes: THEMES, selectedTheme, setSelectedTheme: setTheme}}>
        {selectedTheme && selectedTheme !== DEFAULT_THEME && <HelmetLink/>}
        {children}
      </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };

import i18n from 'i18next';
import k from "./../../i18n/keys";

interface LoadingErrorType {
  error: Error | undefined
}


export function LoadingError({error}: LoadingErrorType) {
  return (
      <div
          className="mt-3 mb-3 text-center text-secondary">{error ? error.message : i18n.t(k.SOMETHING_WENT_WRONG)}{i18n.t(k._5)}</div>);

}
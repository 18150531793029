import { Col, Input } from "reactstrap";

import { Loadable, Notification } from "../../types";

interface OptionType {
  notifications?: Loadable<Notification>
  hideNotifications: Record<string, boolean>
  setHideNotifications: (flags: Record<string, boolean>) => void,
}

export function UserVerificationOption({hideNotifications, setHideNotifications}: OptionType) {
  return (
      <Col sm={12} className="mt-2">
        <Input type="checkbox" name="userVerification" id="userVerification"
               checked={!hideNotifications.userVerification} className="ms-3"
               onChange={(e) => setHideNotifications({...hideNotifications, [e.target.name]: !e.target.checked})}/>
        <span className="ms-2 ps-1">User verification</span>
      </Col>
  )
}

export function DeviceChangeOption({hideNotifications, setHideNotifications}: OptionType) {
  return (
      <Col sm={12} className="mt-2">
        <Input type="checkbox" name="deviceChange" id="deviceChange" checked={!hideNotifications.deviceChange}
               onChange={(e) => setHideNotifications({...hideNotifications, [e.target.name]: !e.target.checked})}/>
        <span className="ms-2 ps-1">Device notifications</span>
      </Col>
  )
}

export function UserMessagesOption({hideNotifications, setHideNotifications}: OptionType) {
  return (
      <Col sm={12} className="mt-2">
        <Input type="checkbox" name="userMessages" id="userMessages" checked={!hideNotifications.userMessages}
               onChange={(e) => setHideNotifications({...hideNotifications, [e.target.name]: !e.target.checked})}/>
        <span className="ms-2 ps-1">User messages</span>
      </Col>
  )
}

export function VitalsRequestsOption({hideNotifications, setHideNotifications}: OptionType) {
  return (
      <Col sm={12}>
        <Input type="checkbox" name="vitals" id="vitals" checked={!hideNotifications.vitals}
               onChange={(e) => setHideNotifications({...hideNotifications, [e.target.name]: !e.target.checked})}/>
        <span className="ms-2 ps-1">Vitals readings</span>
      </Col>
  )
}

export function AlertsOption({notifications, hideNotifications, setHideNotifications}: OptionType) {
  const alerts = notifications?.data?.filter((n: any) => n.type === 'alert-message')
  const types = alerts?.reduce((acc: Record<string, string>, v: Record<string, any>) => {
    if (!acc[v.code]) acc[v.code] = v.name
    return acc
  }, {}) || {}

  const AlertOption = ({code, name}: any) => (
      <Col sm={12}>
        <Input type="checkbox" name={`alerts.${code}`} id={`alerts.${code}`}
               checked={!hideNotifications[`alerts.${code}`]} className="ms-3"
               onChange={(e) => setHideNotifications({...hideNotifications, [`alerts.${code}`]: !e.target.checked})}/>
        <span className="ms-2 ps-1">{name}</span>
      </Col>
  )

  return (
      <Col sm={12} className="mt-2">
        <div>
          <Input type="checkbox" name="appointments" id="appointments" checked={!hideNotifications.appointments}
                 onChange={(e) => setHideNotifications({...hideNotifications, [e.target.name]: !e.target.checked})}/>
          <span className="ms-2 ps-1">Appointments</span>
        </div>
        <div>
          <Input type="checkbox" name="alerts" id="alerts" checked={!hideNotifications.alerts}
                 onChange={(e) => setHideNotifications({...hideNotifications, [e.target.name]: !e.target.checked})}/>
          <span className="ms-2 ps-1">Alerts</span>
          {!hideNotifications.alerts ? Object.keys(types)
              .sort((first, second) => types[first]?.localeCompare(types[second]))
              .map(n => <AlertOption code={n} name={types[n]} key={n}/>) : null}
        </div>
      </Col>
  )
}

